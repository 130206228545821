import React from 'react';


/* Return map fo our address */
 const AddressMap=()=>{

	return (
		<div className="google-map-code">
			<iframe
				title="Contacts"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2469.3920026875303!2d19.497676315932186!3d51.76244049984411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471bb5820a1e91fd%3A0x412b7cf6289af446!2sFirmaTec%20Sp.J.!5e0!3m2!1spl!2sru!4v1633952463041!5m2!1spl!2sru"
				width="100%"
				height="450"
				style={{ border: 0 }}
				allowFullScreen webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"
				aria-hidden="false"
				tabIndex="0"
				loading="lazy"
			>

			</iframe>
		</div>
	);
 }
 export{AddressMap}