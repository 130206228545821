//https://github.com/laraveller/react-authentication-with-breeze-api/blob/main/src/api/axios.js
import axios from "axios";
import { BACK_HOST } from "../js/global";

export default axios.create({
	baseURL: BACK_HOST,//"http://back.fpanel.localhost:8000/",
	//withCredentials: true,
	headers: {
		'Accept': 'application/json',
		'Access-Control-Allow-Origin': '*',

		'Content-Type' : 'application/json',
		'Access-Control-Allow-Credentials':true,
		'Zam-Online': true,
	},
	withCredentials: true
}); 
