/* eslint-disable react-hooks/exhaustive-deps */
//https://reactdatepicker.com/
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import React, { /*forwardRef,*/ useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { IsPLlng } from "../../js/func";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import pl from 'date-fns/locale/pl'; // импорт польской локали
import en from 'date-fns/locale/en-US';

/**
 * Change date-interval
 */
export const PickDates = React.memo(({date_from, date_to, ChangeDatesConfig}) => {

	const { t } = useTranslation();
	const today = new Date();
	const [startDate, setStartDate] = useState(date_from);
	const [endDate, setEndDate] = useState(date_to);

	const setDates = (start, end) => {
		if (start){
			setStartDate(start);
		}
		if (end){
			if (startDate > end)
				end = startDate;
			setEndDate(end)
		}
	}

	useEffect(() => {
		ChangeDatesConfig('date_from', startDate);
	},[startDate])
	useEffect(() => {
		ChangeDatesConfig('date_to', endDate);
	},[endDate])
	

	const handleBtnIntervalClick = (period) => {
		let lastYearStartDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
		if (period === "year")
			lastYearStartDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
		else
			lastYearStartDate = new Date(today.getFullYear() - 20, today.getMonth(), today.getDate());
		setStartDate(lastYearStartDate);
		setEndDate(today);
	}


	/*const InputStart = forwardRef(({ value, onClick }, ref) => (
		<button className="btn btn-primary" onClick={onClick} ref={ref}>
			{value}
		</button>
	));*/

	return (
		<>
		<Alert 
			variant="primary"
			className=" rounded-4 shadow d-inline-block mx-auto"
		>
			<Row className="d-flex align-items-center">
				<Col className="d-inline-block mx-auto rounded align-self-center text-end" sm={"auto"}>
					{t('date_range')}:
				</Col>
				<Col className="d-inline-block" sm={'auto'}>
					{t('from')}{' '}
					<DatePicker 
						id="dt_from"
						showIcon
						toggleCalendarOnIconClick
						showMonthDropdown
						showYearDropdown
						yearDropdownItemNumber={15}
						scrollableYearDropdown
						dateFormat="dd-MM-yyyy" // Установка формата отображения даты
						todayButton={t('today')}
						closeOnScroll={true}
						locale={IsPLlng() ? pl : en}
						selected={startDate} 
						onChange={(date) => setDates(date)} 
						className="form-control m-0"
						maxDate={today < endDate ? today : endDate}
						//withPortal
						//customInput={<InputStart />}
						//minDate={new Date(new Date().getFullYear(), 0, 1)}
					/>
				</Col>
				<Col className="d-inline-block" sm={'auto'}>
					{t('to')}{' '}
					<DatePicker 
						id="dt_to"
						showIcon
						toggleCalendarOnIconClick
						showMonthDropdown
						showYearDropdown
						yearDropdownItemNumber={15}
						scrollableYearDropdown
						dateFormat="dd-MM-yyyy" // Установка формата отображения даты
						todayButton={t('today')}
						closeOnScroll={true}
						locale={IsPLlng() ? pl : en}
						selected={endDate}//{endDate > startDate ? endDate : startDate} 
						onChange={(date) => setDates(null,date)} 
						className="form-control m-0"
						minDate={startDate}
						maxDate={today}
						//withPortal
						//customInput={<InputStart />}
					/>
				</Col>
				<Col sm={'auto'}>
					<button className="btn btn-secondary " onClick={()=>{handleBtnIntervalClick('year')}}>
						{t('last_year')}
					</button>
				</Col>
				<Col sm={'auto'}>
					<button className="btn btn-secondary " onClick={() => {handleBtnIntervalClick('all')}}>
						{t('all_period')}
					</button>
				</Col>
			</Row>
		</Alert>
		</>
	);
});
