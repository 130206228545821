import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';



// Spinner in modal
export const SpinnerWait = ({children, show}) => {

	return(
		<>
			{(show &&
				<div className="spinner-overlay">
					<Modal
						show={show}
						backdrop="static"
						size="lg"
						centered
						className='fade bd-example-modal-lg spinner_wait'
						data-backdrop="static" data-keyboard="false" tabIndex="-1"
						contentClassName="bg-transparent border-0 position-relative"
						style={{position:"fixed"}}
					>
						<div className="position-absolute top-50 start-50 translate-middle">
							<Spinner 
								animation="grow" 
								role="status" 
								style={{ width: "7rem", height: "7rem" }}
							>
							</Spinner>
						</div>
					</Modal>
				</div>
			) || ""}
			{children}
		</>
	);
};
