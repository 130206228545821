import React, {Suspense} from 'react';
import { Spinner } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';
import App from './App';
import { AuthProvider } from './Context/AuthContext';


	/* Loading language */
	const loadingMarkup = (
		<div className='py-4 text-center'>
			{/* <Spinner animation="border" variant="primary" /> */}
			<Spinner animation="border" variant="info" />
			<h2>Loading...</h2>
		</div>
	);


createRoot(document.getElementById('root')).render(
	<AuthProvider>
		<Suspense fallback={loadingMarkup}>
			<App />
		</Suspense>
	</AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
