//https://github.com/armenstepanyan/react-google-recaptcha3
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { RecaptchaSiteKey } from '../js/global';

	// Init or destroy RECAPTCHA
	export const initRecaptcha = async (init=true) => {
		if (init){
			ReactRecaptcha3.init(RecaptchaSiteKey).then(status => {
				// status: success/error
				// success - script is loaded and greaptcha is ready
				// error - script is not loaded
				if (status==='success'){
					return true;
					//console.log('Init recaptcha: ',status)
				}
				else{
					alert("ERROR with reCaptcha.\nPlease, refresh this page!");
					return false;
				}
			});
		}
		else
			ReactRecaptcha3.destroy();
	}