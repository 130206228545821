import React, { useState, useEffect } from "react";
import { Button, Form, Toast } from "react-bootstrap";
import { FiletypePdf, HouseDoor } from "react-bootstrap-icons";
import { useNavigate } from 'react-router-dom';
import {SuitHeartFill } from "react-bootstrap-icons";

/* Button PDF-download (with props.lnk) */
export const BtnPDF = (props) => {

	if (props.lnk)
		return (
			<>
				<Button variant="outline-danger" href={props.lnk} target="_blank" size="sm">
					<FiletypePdf className="fs-4" />
				</Button>
			</>
		);

	return;
};


/* Button return to home */
export const HomeBtn = () => {
	let navigate = useNavigate();
	const SubmitMainPage = (event) => {
		event.preventDefault();
		// 👇️ redirect
		navigate('/');
	}

	return (
		<Button onClick={SubmitMainPage} className="bg-gradient btn-rounded" style={{padding: "6px 8px"}}>
			<div>
				<HouseDoor />&nbsp;Na stronę główną
			</div>
		</Button>
	);
}



/**
 * 
 * Component of checkbox with params
 */
export const Checkbox = ({ name, label, value, onChange/*, register*/ }) => {
	return(
		<Form.Check 
			type='switch' 
			label={label}
			id={name} 
			name={name}
			checked={value}
			onChange={onChange}
			/*ref={...register(name)}*/
		>
		</Form.Check>
	);
};



export const MyToast = (props) => {
	const [show, setShow] = useState(props.show);
	useEffect(() => {
		props.parentCallback({ind:props.ind, show, from:'toast'})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show])

	return (
	<>
		{/* <div className="toast-container position-fixed top-0 end-0 p-3"> */}
			<Toast 
				onClose={() => setShow(false)} 
				show={show} 
				delay={3000} 
				autohide 
				aria-live="assertive" 
				aria-atomic="true"
				bg={props.bg || "success"}
			>
				<Toast.Header>
					<SuitHeartFill />&nbsp; 
					<strong className="me-auto">Application</strong>
				</Toast.Header>
				<Toast.Body>
					<strong className="text-white">{props.msg}</strong>
				</Toast.Body>
			</Toast>
		{/* </div> */}
	</>
	)
};