/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, FloatingLabel, Form, FormGroup, Modal, Offcanvas, Row, Stack } from "react-bootstrap";
import { isReq_data_Obj, getScreenSize, copyToClipboard, isEmptyArr } from "../../js/func";
import useAuthContext from "../../Context/AuthContext";
import useCardContext from "../../Context/CartContext";
import useAxiosContext from "../../Context/axios_req";
import useToastContext from "../../Context/ToastContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import { Buildings, Truck, CashCoin, Check2Circle, XCircle, CardList, ReceiptCutoff } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { capFrst } from "../../js/func";
import { FrmOrderComplete } from "./frmOrderComplete";


// export const FrmDelivery1 = ( { DefModalShow=false, HideFrmDelivery }) =>{
// 	const url = "zam/delivery/";
// 	const [showModal, setShowModal] = useState(DefModalShow);
// 	const [delivery, setDelivery] = useState([]);
// 	const [usrOpt, setUsrOpt] = useState({pay: '', delivery: ''})
// 	const [req, setReq] = useState({req:false, to:''});

// 	const { req_data, Get_data } = useAxiosContext();

// 	/**
// 	 * When modal show
// 	 */
// 	useEffect(() => {
// 		const GetDelivery = async () => {
// 			setReq({req:true, to:'delivery_paym'})
// 			await Get_data(url, 
// 			{
// 				//id: 
// 			});
// 		}
// 		setShowModal(DefModalShow);
// 		if (DefModalShow && !isReq_data_Obj(delivery)){
// 			GetDelivery()
// 		}
// 	}, [DefModalShow])


// 	/**
// 	 * When get data from request
// 	 */
// 	useEffect(() => {
// 		let getdata = (((req.to == 'delivery_paym' || req.to == 'get_Itm')) && req_data > 0) || isReq_data_Obj(req_data);
// 		if (getdata && req.req){
// 			setReq({req:false, to:''})
// 			if (req.to == 'delivery_paym'){
// 				setDelivery(req_data)
// 			}
// 		}
// 	// eslint-disable-next-line react-hooks/exhaustive-deps
// 	},[req_data])


// 	const setUsrOptions = (opt='1', val='2') =>{
// 		setUsrOpt({
// 			...usrOpt,
// 			[opt]: val
// 		})
// 	}

// 	return(
// 		<>
// 		{(!!isReq_data_Obj(delivery)) &&
// 			<Modal
// 				show={showModal}
// 				onHide={HideFrmDelivery()}
// 			>
// 				<Modal.Body>

// <Alert variant="danger" className="block-down"></Alert>

// 					<Row>
// 						<Col>
// 							<Card className="shadow">
// 								<Card.Header className="block-down" style={{ backgroundClip: "text"}}>
// 									Delivery
// 								</Card.Header>
// 								<Card.Body>
// 									<Card.Title>
// 										<DeliveryVariants delivery={delivery?.delivery} setDeliveryOpt={(opt, val)=>setUsrOptions(opt,val)}/>
// 									</Card.Title>
// 									<Card.Text></Card.Text>
// 								</Card.Body>
// 							</Card>
// 						</Col>
// 					</Row>
// &nbsp;
// 					<Row>
// 						<Col>
// 							<Card className="shadow">
// 								<Card.Header className="block-down" style={{ backgroundClip: "text"}}>
// 									Payment
// 								</Card.Header>
// 								<Card.Body>
// 									<Card.Title>
// 										{/* <DeliveryVariants delivery={delivery?.delivery} setDeliveryOpt={(opt, val)=>setUsrOptions(opt,val)}/> */}
// 										<PaymentList payments={delivery.payment} setPayOpt={(opt, val)=>setUsrOptions(opt,val)}/>
// 									</Card.Title>
// 									<Card.Text></Card.Text>
// 								</Card.Body>
// 							</Card>
// 						</Col>
// 					</Row>
// 					<hr />
// 					<div className="d-flex justify-content-end ">
// 					<Button variant="danger"
// 						className="ani-shad shadow" 
// 						onClick={() => console.log("usropt:", usrOpt)}
// 					>
// 						TEST
// 					</Button> &nbsp;
// 					<Button 
// 						variant="primary" 
// 						onClick={HideFrmDelivery()}
// 						className="ani-shad shadow"
// 					>
// 						Hide
// 					</Button>
// 					</div>
// 				</Modal.Body>
// 			</Modal>
// 		}
// 		</>
// 	)
// }





export const FrmDelivery = ({ DefModalShow=false, HideFrmDelivery }) =>{
	const { user_data } = useAuthContext();
	const { req_data, Get_data, Post_data } = useAxiosContext();
	const { ADDToast } = useToastContext();
	const { userCart, cartSum, ClearCart, storedConf } = useCardContext({});
	let navigate = useNavigate();
	const {t} = useTranslation();
	const urldel = "zam/delivery/";
	const urlord = "zam/orders/";
	const Screen = getScreenSize();
	const [showModal, setShowModal] = useState(DefModalShow);
	const [delivery, setDelivery] = useState([]);
	const [usrOpt, setUsrOpt] = useState({
		//addr:user_data?.User?.Address, 
		addrdeliv: '',
		addrfakt: '',
		delivery: '', 
		pay: '', 
		notes: '',
		fakt: !!user_data?.User?.NIP
	})
	const [req, setReq] = useState({req:false, to:''});
	const [enBtn, setEnBtn] = useState(false);
	const [newOrdData, setNewOrdData] = useState({
		show: false,
		ordnum: ''
	});
	const ref_chk = useRef();
	const ref_canvas = useRef();

	/**
	 * When modal show
	 */
	useEffect(() => {
		const GetDelivery = async () => {
			setReq({req:true, to:'delivery_paym'})
			await Get_data(urldel, 
			{
				//id: 
			});
		}
		setShowModal(DefModalShow);
		if (DefModalShow && !isReq_data_Obj(delivery)){
			GetDelivery()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [DefModalShow])


	/**
	 * When get data from request
	 */
	useEffect(() => {
		let getdata = (((req.to === 'delivery_paym' || req.to === 'ord_create')) && req_data > 0) || isReq_data_Obj(req_data);
		if (getdata && req.req){
			setReq({req:false, to:''})
			if (req.to === 'delivery_paym'){
				setDelivery(req_data)
				const addresses = {
					...req_data?.addr,
					Nazw: user_data?.User?.UserName,
					osoba: user_data?.User?.osoba,
					sw_firm: !!user_data?.User?.NIP
				};
				setUsrOptions('addrdeliv', addresses);
				setUsrOptions('addrfakt', addresses);

			}
			if (req.to === 'ord_create'){
				setNewOrdData((prev) => ({
					...prev,
					...req_data.data,
					show: true
				}))
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[req_data])


	const setUsrOptions = async (opt='1', val='2') =>{
		const setNewVal = async (opt, val) => {
			setUsrOpt((prevUsrOpt) => ({
				...prevUsrOpt,
				[opt]: val,
			}));
		}
		await setNewVal(opt, val);
		// Временная заглушка.......................................................
		/*if (opt === 'addrdeliv')
			await setNewVal('addrfakt', val);*/
	}

	const Chg_Fact_Parag = () =>{
		setUsrOpt({
			...usrOpt, 
			fakt: ref_chk.current.checked
		})
		if (ref_chk.current.checked){
			ref_chk.current.label=t('zam.frmdelivery.fact')
		}
		else{
			ref_chk.current.label=t('zam.frmdelivery.paragon')
		}
	}


	/**
	 * Create order on api-server
	 */
	const Create_Order = async () =>{
		let order = {
			...usrOpt,
			cart: userCart,
			sum: cartSum
		}
		const CreateOrd = async () => {
			setReq({req:true, to:'ord_create'})
			await Post_data(urlord, 
			{
				...order
			});
		};
		await CreateOrd();
	}

	/**
	 * Hide form of new order
	 * @param {boolean} v - to show or hide form of new order
	 */
	const HideFrmNewOrd = (v) =>{
		copyToClipboard(newOrdData?.ordnum);
		setNewOrdData({
			ordnum: '',
			show: v,
		});
		ClearCart();
		ADDToast(t('zam.frmcmpl.ord_cr_cp', {ord_num: newOrdData?.ordnum}), 'primary');
		navigate('/towary');
	}

	const IsValidAddr = (val) => {
		if (!isEmptyArr(req_data?.delivery))
			setEnBtn(val)
	}


	return(
		<>
		{(!!isReq_data_Obj(delivery)) &&
		<Offcanvas 
			show={showModal}
			onHide={HideFrmDelivery()} 
			scroll={true}
			backdrop={true}
			placement={'end'}
			ref={ref_canvas}
			className={`${Screen === "xl" ? "custom-offcanvas" : ""}`}
		>
			<Container className="bg-primary bg-gradient bg-opacity-10 m-0 p-0  d-flex flex-column" style={{ minHeight: "100vh" }}>
				{/* HEADER OF CANVAS */}
				<Alert 
					variant="warning" 
					className="block-down m-3 p-1"
				>
					<Offcanvas.Header closeButton className="m-1 p-1 shadow rounded-3">	
						<Offcanvas.Title>
							{t('zam.frmdelivery.header')}
						</Offcanvas.Title>
					</Offcanvas.Header>
				</Alert>

				{/* BODY OF CANVAS */}
				<Offcanvas.Body className="d-flex flex-column flex-grow-1">							
					{/* Address delivery */}
					<Row className="pb-4">
						<Col>
							<Card className="shadow">
								<Card.Header className="block-down" style={{ backgroundClip: "text"}}>
									<strong className="ani-btn btn-ico"><Buildings className=""/>&nbsp; {t('zam.frmdelivery.addrdeliv')}</strong>
								</Card.Header>
								<Card.Body>
									<Card.Title>
										<DeliveryAddress address={usrOpt?.addrdeliv} setAddOpt={(opt, val)=>setUsrOptions('addrdeliv',val)} isAddrPres={(v)=>IsValidAddr(v)} />
									</Card.Title>
									<Card.Text></Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					{/* Check-box Factura */}
					<Row className="pb-2">
						<Col className="d-flex flex-row-reverse">
							<Form.Check 
								type='switch' 
								ref={ref_chk}
								id="rem_sw" 
								name="rem_sw" 
								label= {usrOpt.fakt ? t('zam.frmdelivery.fact') : t('zam.frmdelivery.paragon')}
								onChange={Chg_Fact_Parag}
								checked={usrOpt.fakt}
							>
							</Form.Check>
						</Col>
					</Row>
					{/* Address Faktury */}
					{usrOpt.fakt && 
						<Row className="pb-4">
							<Col>
								<Card className="shadow">
									<Card.Header className="block-down" style={{ backgroundClip: "text"}}>
										<strong className="ani-btn btn-ico"><ReceiptCutoff className=""/>&nbsp; {t('zam.frmdelivery.addrfact')}</strong>
									</Card.Header>
									<Card.Body>
										<Card.Title>
											<DeliveryAddress address={usrOpt?.addrfakt} setAddOpt={(opt, val)=>setUsrOptions('addrfakt',val)} isAddrPres={(v)=>IsValidAddr(v)} />
										</Card.Title>
										<Card.Text></Card.Text>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					}
					{/* Delivery */}
					{//storedConf?.Nazw3[13] === 'T' && 
						<Row className="pb-4" hidden={storedConf?.Nazw3[13] === 'N'}>
							<Col>
								<Card className="shadow">
									<Card.Header className="block-down" style={{ backgroundClip: "text"}}>
										<strong className="ani-btn btn-ico"><Truck className=""/>&nbsp; {t('zam.cart.delivery')}</strong>
									</Card.Header>
									<Card.Body>
										<Card.Title>
											{!isEmptyArr(delivery?.delivery) ?
												<DeliveryVariants delivery={delivery?.delivery} setDeliveryOpt={(opt, val)=>setUsrOptions(opt,val)} storedConf={storedConf} />
											:
												<Alert variant="danger" className="shadow">
													{t('zam.cart.no_delivery')}
												</Alert>
											}
										</Card.Title>
										<Card.Text></Card.Text>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					}
					{/* Payment */}
					{//storedConf?.Nazw3[12] === 'T' && 
						<Row className="pb-4" hidden={storedConf?.Nazw3[12] === 'N'}>
							<Col>
								<Card className="shadow">
									<Card.Header className="block-down" style={{ backgroundClip: "text"}}>
										<strong className="ani-btn btn-ico"><CashCoin className=""/>&nbsp; {t('zam.frmdelivery.payment')}</strong>
									</Card.Header>
									<Card.Body>
										<Card.Title>
											<PaymentList payments={delivery.payment} setPayOpt={(opt, val)=>setUsrOptions(opt,val)}/>
										</Card.Title>
										<Card.Text></Card.Text>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					}
					{/* Notes */}
					<Row className="pb-4">
						<Col>
							<Card className="shadow">
								<Card.Header className="block-down" style={{ backgroundClip: "text"}}>
									<strong className="ani-btn btn-ico"><CardList className=""/>&nbsp; {t('zam.frmItem.not')}</strong>
								</Card.Header>
								<Card.Body>
									<Card.Title>
										<Notes setNoteOpt={(opt, val)=>setUsrOptions(opt,val)}/>
									</Card.Title>
									<Card.Text></Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					{/* SUMMA */}
					<Row className="pb-4">
						<Col>
							<Card className="bg-transparent border-0 m-0">
								<Card.Body className="p-1">
									<Card.Title>
										<SumWithDeliv delivery={usrOpt?.delivery} storedConf={storedConf} />
									</Card.Title>
									<Card.Text></Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					{/* Buttons */}
					<Row className="d-flex mt-auto align-content-end flex-wrap">
						<Col>
							<hr />
							<Stack direction="horizontal" gap={3} className="mt-auto">
								<div className="tow-btn ">
									<Button variant="danger"
										className="ani-shad shadow btn-ico" 
										onClick={HideFrmDelivery()}
									>
										<XCircle className="ani-btn"/>  &nbsp;{t('zam.frmdelivery.cancel')}
									</Button>
								</div>
								<div className="tow-btn ms-auto ">
									<Button 
										variant="primary" 
										className="ani-shad shadow btn-ico"
										onClick={() => Create_Order()}
										disabled = {!enBtn}
									>
										<Check2Circle className="ani-btn"/>  &nbsp;{t('zam.frmdelivery.ord')}
									</Button>
									<FrmOrderComplete data={newOrdData} setFrmOrdComplShow={(v) => {HideFrmNewOrd(v)}}/>
								</div>
							</Stack>
						</Col>
					</Row>
				</Offcanvas.Body>
			</Container>
		</Offcanvas>
		}
		</>
	)
}


/**
 * ADDRESSES block
 * 
 * @param {callback-function} param0 
 * @returns 
 */
const DeliveryAddress = React.memo(({ address, setAddOpt, isAddrPres }) => {
	
	const [addrStr, setAddrStr] = useState('');
	const [modalSh, setModalSh] = useState(false);
	const {t} = useTranslation();

	useEffect(() => {
		let address_wo_tel = (address?.Mias + address?.Ulic + address?.KodP/* + addr?.kraj*/).trim();
		const newIsAddr = !(!address_wo_tel || address_wo_tel.trim() === '');
	
		if (newIsAddr) {
			let str = (address?.Nazw ? address?.Nazw + ', <br />' : '') + 
				(address.sw_firm ? (address?.osoba ? address.osoba + ', <br />' : '') : "")+ 
				address?.Ulic + ', <br /> ' +
				address?.KodP + ', ' + address?.Mias + ', <br />' +
				address?.kraj + ' <br /> ' +
				'tel: ' + address.tel
			setAddrStr(str);
		}
		else{
			setAddrStr(t('zam.frmeditaddr.no_addr'));
		}
		isAddrPres(newIsAddr);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [address]);

	// Set new delivery address
	const setNewAddr = (val) => {
		//setAddr(val);
		setAddOpt('addrdeliv', val)
	}

	const ShowModal = (val=false) =>{
		setModalSh(val)
	}

	return(
		<>
			<Alert 
				placeholder={t('zam.frmdelivery.entaddr')}
				className="ani-shad shadow rounded-3 bg-info bg-gradient bg-opacity-25 text-dark"
				disabled
				style={{ cursor: 'text' }}
				onClick={()=>{ShowModal(true)}}
			>
				<small>{parse(addrStr)}</small>
			</Alert>

			<FormChgAddress show={modalSh} SetShowModal={(val)=>ShowModal(val)} addr={address} setNewAddr={(val) => setNewAddr(val)} />
		</>
	)
});



/**
 * Variants of delivery and it's params
 * 
 * @param {delivery params object} param0 
 * @param {callback-function} param1 
 * @returns 
 */
const DeliveryVariants = React.memo(({ delivery, setDeliveryOpt, storedConf }) => {
	//const { user_data } = useAuthContext();
	const {t} = useTranslation();
	const refItm = useRef();
	const [deliveryUsr, setDeliveryUsr] = useState({});
	useEffect(() => {
		ChgDeliv()
	},[])

	useEffect(()=>{
		setDeliveryOpt('delivery', deliveryUsr)
	},[deliveryUsr])

	const ChgDeliv = async () => {
		const selectedDelivery = delivery.find(v => v.ID === (refItm.current?.value ?? delivery[0]?.ID));
		setDeliveryUsr(selectedDelivery)
	}

	return(
		<>
		{(delivery?.length) &&
			(
				<>
				<Row>
					<Col sm={'auto'}>
						<small>{t('zam.frmdelivery.method')}: </small>
					</Col>
					<Col>
						<Form.Select
							size="sm"
							aria-label="Select delivery" 
							onChange={ChgDeliv}
							ref={refItm}
							className="shadow rounded-3"
							defaultValue={0}
							disabled={delivery?.length === 1}
						>
							{delivery?.map((v) => (
								((v !== "") &&
									<option value={v.ID} key={v.ID} vval={v.ID}>{v.Nazw}</option>
								)
							))}
						</Form.Select>
					</Col>
				</Row>
				<Row className="">
					<Col xs={'auto'} className="d-flex align-items-center" >
						<small>{t('zam.price')}: </small>
					</Col>
					<Col className="d-flex align-items-center justify-content-end">
						<Alert
							key="deliv"
							variant={'primary'}
							className='m-1 text-md-end rounded-4 shadow bg-opacity-50 p-2 mt-2 ani-shad'
							style={{ display: 'inline-block', width: 'auto' }}
						>
							<small className=""><b>{deliveryUsr?.CB1}</b> <i className="text-muted">{storedConf?.Ceny?.wal}</i></small>
						</Alert>
					</Col>
				</Row>
				</>
			)
		}
		</>
	)
})





/**
 * Variants of payments and it's costs
 * 
 * @param {payments params object} param0 
 * @param {callback-function} param1 
 * @returns 
 */
const PaymentList = React.memo(({ payments, setPayOpt }) => {
	const {t} = useTranslation();
	const [paymUsr, setPaymUsr] = useState({});

	const refItm = useRef();

	useEffect(() => {
		ChgPaym()
	},[])
	const ChgPaym = async() => {
		/*const selectedOption = Object.entries(payments).find(([k, v]) => k === refItm.current?.value);
		const [key, value] = selectedOption;
		const selectedObject = { [key]: value };*/

		const selectedPaym = payments.find(v => v.FP === (refItm.current?.value ?? payments[0]?.FP));

		await setPaymUsr(selectedPaym)
		await setPayOpt('pay', {...selectedPaym})
	}

	return (
		<>
			{(payments?.length) &&
				(
					<>
						<Row>
							<Col sm={'auto'}>
								<small>{t('zam.frmdelivery.method')}: </small>
							</Col>
							<Col>
								<Form.Select
									size="sm"
									aria-label="Select payment" 
									onChange={ChgPaym}
									ref={refItm}
									className="shadow rounded-3"
									defaultValue={0}
									disabled={payments?.length === 1}
								>
									 {payments.map((v,i) => (
										<option value={v.FP} key={'pay' + v.FP + i}>{v.FP}</option>
									))}
								</Form.Select>
							</Col>
						</Row>
						<Row className="">
							<Col xs={'auto'} className="d-flex align-items-center" >
								<small>{t('zam.frmdelivery.days')}: </small>
							</Col>
							<Col className="d-flex align-items-center justify-content-end">
								<Alert
									key="deliv"
									variant={'primary'}
									className='m-1 text-md-end rounded-4 shadow bg-opacity-50 p-2 mt-2 ani-shad'
									style={{ display: 'inline-block', width: 'auto' }}
								>
									<small className=""><b>{paymUsr?.DN}</b> <i className="text-muted text-lowercase">{t('zam.frmdelivery.days')}</i></small>
								</Alert>
							</Col>
						</Row>
					</>
				)
			}
		</>
	)
})



/**
 * Notes
 * 
 * @param {callback-function} param0 
 * @returns 
 */
const Notes = ({ setNoteOpt }) => {
	const [notes, setNotes] = useState({
		param: '', 
		val: ''
	});
	//const [zID, setZID] = useState('');
	const {t} = useTranslation();
	const ref = useRef();
	const refzID = useRef();

	useEffect(()=>{
		ChgNotes(true, ref)
		ChgNotes(true, refzID)
	},[])

	const ChgNotes = async (disable=true, refer) => {
		if (disable) {
			//ref.current.setAttribute("disabled", true);
			refer.current.style.backgroundColor = "#f0f0f0";

			setNotes({
				param: refer.current.name,
				val: refer.current.value,
			});
		} else {
			//ref.current.removeAttribute("disabled");
			refer.current.style.backgroundColor = "transparent";
			refer.current.focus();
		}
	}

	useEffect(()=>{
		if (notes?.param)
			setNoteOpt(notes.param, notes.val)
	},[notes])

	return(
		<div>
			<Form.Control 
				as="textarea" 
				ref={ref}
				name="notes"
				//defaultValue={notes?.val}
				placeholder={t('zam.frmdelivery.wishes')}
				onBlur={() => ChgNotes(true, ref)}
				onMouseDown={()=> ChgNotes(false, ref)}
			/>

			<br />
			<FloatingLabel 
				label={t('zam.frmdelivery.zID')} 
				className="shadow rounded-3"
			>
				<Form.Control 
					ref={refzID}
					size="sm" 
					type="text" 
					name="zID"
					placeholder={t('zam.frmdelivery.zID')}
					onBlur={() => ChgNotes(true, refzID)}
					onMouseDown={()=> ChgNotes(false, refzID)}
				/>
			</FloatingLabel>
		</div>
	)
};


/**
 * Summ with delivery
 * @param {object} delivery 
 * @returns DOM
 */
const SumWithDeliv = React.memo(({ delivery, storedConf }) => {
	const { userCart, cartSum, oneWAL } = useCardContext({});
	//const { user_data } = useAuthContext();
	const { t } = useTranslation();
	const [sumAll, setSumAll] = useState("");
	useEffect(() => {
		const DelivCB1 = delivery?.CB1;
		const DelivCB1Val = (DelivCB1 !== null && DelivCB1 !== undefined) ? parseFloat(DelivCB1) : 0;
	
		// let sum = parseFloat(user_data?.User?.Ceny?.NB == "N" ? cartSum.Nsum : cartSum.Bsum) + DelivCB1Val
		let sum = parseFloat(cartSum.Bsum) + DelivCB1Val
		setSumAll(sum.toFixed(2))
	},[delivery])
	return(
		<>
			{/*(delivery)&&*/ oneWAL && (storedConf?.Ceny?.wal === userCart[0]?.wal) &&
				(
					<Alert variant="info" className="shadow rounded-4">
						<Row className="">
							<Col xs={'auto'} className="d-flex align-items-center" >
								{/* <strong>{t('zam.frmdelivery.total')} ({t('zam.' + storedConf?.Ceny?.NB)}): </strong> */}
								<strong>{t('zam.frmdelivery.total')} ({t('zam.B')}): </strong>
							</Col>
							<Col className="d-flex align-items-center justify-content-end">
								<Alert
									key="sumall"
									variant={'primary'}
									className='m-1 text-md-end rounded-4 shadow bg-opacity-50 p-2 mt-2 ani-shad'
									style={{ display: 'inline-block', width: 'auto' }}
								>
{/* =================================================================================================================================================================*/}
									<small className=""><b>{sumAll}</b> 
									<i className="text-muted">&nbsp;
										{storedConf?.Ceny?.wal}
									</i></small>
								</Alert>
							</Col>
						</Row>
					</Alert>
				)
			}
		</>
	)
})




/**
 * Form to change address
 * @param {*} param0 
 * @returns 
 */
export const FormChgAddress = React.memo(({ show, SetShowModal, addr, setNewAddr }) => {
	const { t } = useTranslation();
	const { user_data } = useAuthContext();
	const [defVals, setDefVals] = useState({
		Nazw: addr?.Nazw || user_data?.User?.UserName,
		osoba: addr?.osoba || user_data?.User?.osoba,
		Ulic: addr?.Ulic,
		KodP: addr?.KodP,
		Mias: addr?.Mias,
		kraj: addr?.kraj,
		tel: addr?.tel,
		sw_firm: !!addr?.NIP || !!user_data?.User?.NIP
	})
	const ref_osoba = useRef();
	const ref_chk = useRef();

	const handleClose = () => {
		SetShowModal(false);
	};

	useEffect(()=>{
		if (show)
			setDefVals({...addr})
	},[show])


	const {
		register,
		watch,
		setValue,
		formState: {
			errors,
			isValid,
		},
		handleSubmit,
	} = useForm({
		mode: "all",
		defaultValues: defVals
	});

	useEffect(()=>{
		if (watch('sw_firm')){
			setValue("osoba", defVals.osoba)
		}
		else 
			setValue("osoba", "")
			
	},[watch('sw_firm')])/**/

	// Set new values of address and hide form
	const onSubmit = (data) => {
		setNewAddr(data)
		SetShowModal(false);
	}

	return (
		<Modal
			show={show}
			onHide={handleClose}
			backdrop="static"
			centered
		>
			<Modal.Header className="p-2 px-3" closeButton>
				<Modal.Title>
					{t('zam.frmeditaddr.header')}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<FormGroup className="">
						<Row>
							<Col>
								<Form.Check 
									type='switch' 
									ref={ref_chk}
									id="sw_firm" 
									name="sw_firm" 
									label={watch('sw_firm') ? capFrst(t('zam.frmeditaddr.firm')) : capFrst(t('zam.frmeditaddr.private'))}
									{...register("sw_firm")} 
									className="pb-4"
								>
							</Form.Check>
							</Col>
						</Row>
						<Row className="align-items-center">
							<Col>
								<FloatingLabel label={watch('sw_firm') ? capFrst(t('zam.frmeditaddr.firm')) : capFrst(t('zam.frmeditaddr.name_surn'))} className="pb-4">
									<Form.Control 
										size="sm" 
										type="text" 
										placeholder={t('zam.frmeditaddr.name_surn')} 
										className={(errors?.Nazw && "is-invalid") || "is-valid"}
										{...register("Nazw", {
											required: {
												value: true,
												message: "Name is required"//t('form.login.errors.req'),
											},
											minLength: {
												value: 1,
												message: /*t('form.login.errors.min', {min_nr: 1})*/ "Minimum srting is 1",
											},
										})}
									/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row 
							className="align-items-center"
							hidden={watch('sw_firm') ? "" : "hidden"}
						>
							<Col>
								<FloatingLabel label={capFrst(t('osoba'))} className="pb-4">
									<Form.Control 
										size="sm" 
										type="text" 
										ref={ref_osoba}
										placeholder={t('osoba')} 
										className={(errors?.osoba && "is-invalid") || "is-valid"}
										{...register("osoba")}
										defaultValue={defVals.osoba}
									/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel label={t('zam.frmeditaddr.street')} className="pb-4">
									<Form.Control 
										size="sm" 
										type="text" 
										placeholder={t('zam.frmeditaddr.street')} 
										className={(errors?.Ulic && "is-invalid") || "is-valid"}
										{...register("Ulic",{
											required: true
										})}
									/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel label={t('zam.frmeditaddr.kodp')} className="pb-4">
									<Form.Control 
										size="sm" 
										type="text" 
										placeholder={t('zam.frmeditaddr.kodp')}
										className={(errors?.KodP && "is-invalid") || "is-valid"}
										{...register("KodP",{
											required: true
										})}
									/>
								</FloatingLabel>
							</Col>
							<Col>
								<FloatingLabel label={t('zam.frmeditaddr.city')} className="pb-4">
									<Form.Control 
										size="sm" 
										type="text" 
										placeholder={t('zam.frmeditaddr.city')} 
										className={(errors?.Mias && "is-invalid") || "is-valid"}
										{...register("Mias",{
											required: true
										})}
									/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel label={t('zam.frmeditaddr.country')} className="pb-4">
										<Form.Control 
										size="sm" 
										type="text" 
										placeholder={t('zam.frmeditaddr.country')}
										className={(errors?.kraj && "is-invalid") || "is-valid"}
										{...register("kraj",{
											required: true
										})}
									/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel label={t('zam.frmeditaddr.tel')} className="pb-4">
									<Form.Control 
										size="sm" 
										type="text" 
										placeholder={t('zam.frmeditaddr.tel')}
										className={(errors?.tel && "is-invalid") || "is-valid"}
										{...register("tel",{
											required: true
										})}
									/>
								</FloatingLabel>
							</Col>
						</Row>
					</FormGroup>

					<hr />
					{/* Buttons */}
					<Stack direction="horizontal" gap={3}>
						<div className="tow-btn ">
							<Button variant="danger"
								className="ani-shad shadow btn-ico" 
								onClick={handleClose}
							>
								<XCircle className="ani-btn"/>  &nbsp;{t('zam.frmdelivery.cancel')}
							</Button>
						</div>
						<div className="tow-btn ms-auto ">
							<Button 
								variant="primary" 
								className="ani-shad shadow btn-ico"
								type='submit' 
								disabled={!isValid}
							>
								<Check2Circle className="ani-btn"/>  &nbsp;{t('zam.apply')}
							</Button>
						</div>
					</Stack>
				</Form>
			</Modal.Body>
		</Modal>
	)
})