import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cntr_horiz } from '../App';
import '../css/Arrowbadge.css';
import { Row, Col, Container, Card, OverlayTrigger, Tooltip, Form, Button, Badge, FormControl, Alert, Stack } from 'react-bootstrap';

import { FaTrash } from 'react-icons/fa';
import { BsCartX } from "react-icons/bs";
import { IoAddSharp, IoRemoveSharp } from 'react-icons/io5';
import { Cart4, Truck } from 'react-bootstrap-icons';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
//import useAuthContext from '../Context/AuthContext';
import useCardContext from '../Context/CartContext';
import useToastContext from '../Context/ToastContext';
import { FrmDelivery } from './Forms/frmDelivery';
import { IsPLlng } from '../js/func';
//import { formatNumber } from '../js/func';


export const ShopCart = () => {
	const {t} = useTranslation();
	let navigate = useNavigate();
	let placement = "bottom";
	let sumqty = 0;
	const { userCart, ClearCart, cartSum, GetUserConfig, storedConf, oneWAL } = useCardContext({});
	const { ADDToast } = useToastContext();
	//const { user_data } = useAuthContext();

	useEffect(()=>{
		/*if (!user_data?.User?.ID)
			navigate('/towary');*/
		if (!storedConf){
			GetUserConfig()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	// Remove all from cart
	const removeCart = () => {
		if (window.confirm(t('zam.cart.clear_conf'))) {
			ClearCart();
			ADDToast(t('zam.cart.was_cleared'), 'secondary');
			navigate('/towary');
		}
	}
	const goToTows = () =>{
		navigate('/towary');
	}

	const [frmDelivery, setFrmDelivery] = useState(false)
	const showDeliveryFrm = () =>{
		setFrmDelivery(true)
	}
	const hideDeliveryFrm = () =>{
		setFrmDelivery(false)
	}

	return (
		<>
		{storedConf?.Nazw3 && 
			<Container fluid>
				<Card className='shadow rounded-4'>
					<Card.Header className={`${cntr_horiz} card-primary`} style={{ display: 'flex', alignItems: 'center' }}>
						<OverlayTrigger
							placement={placement}
							overlay={
								<Tooltip id={`tooltip-${placement}`}>
									{t("zam.cart.cart")}
								</Tooltip>
							}
						>
							<strong style={{ display: 'flex', alignItems: 'center' }}>
								{/* <Building color="royalblue" /> &nbsp;  */}
								<Cart4 /> &nbsp;
								{t("zam.cart.cart")}
							</strong>
						</OverlayTrigger>
					</Card.Header>


					{/* Context */}
					<Card.Body className='rounded-4 bg-opacity-25 shadow-lg px-4' >
						<div className={userCart.length ? " " : "empty"} >
							{userCart.length ? 
								<>
								{userCart.forEach(el => sumqty += Number.parseFloat(el.qty))}
									{userCart.map((el, i) => (
										<CartItem key={el.ID + JSON.stringify(el.cechy) + el.Nazw} item={el} idx={i}/>
									))}

									<Stack direction="horizontal" gap={3}>
										<Button 
											variant='danger' 
											className=" ani-shad shadow btn-ico" 
											onClick={e => {removeCart()}}
										>
											<BsCartX /> &nbsp;
											{t('zam.cart.clear')}
										</Button>
										{/* Suma */}
										<div className="ms-auto">
											<Alert
												key="sum"
												variant={'primary'}
												className='m-1 text-md-end rounded-4 shadow bg-opacity-50 p-2'
												style={{ display: 'inline-block', width: 'auto' }}
											>
												<strong className={!oneWAL ? "text-danger" : "" }>
													{t('zam.cart.summ')}:&nbsp;
													{oneWAL ? 
														storedConf?.NB === "N" ? cartSum.Nsum : cartSum.Bsum
													:
														"--0--"
													}
												</strong> 
											</Alert>
										</div>
      							<div className="">
										<Button 
											variant='primary' 
											className="ani-shad shadow btn-ico"
											onClick={showDeliveryFrm}
										>
											<Truck /> &nbsp;
											{/* {t('zam.cart.delivery')} */}
											{t('zam.frmdelivery.ord')}
										</Button>
										<FrmDelivery DefModalShow={frmDelivery} HideFrmDelivery={() => hideDeliveryFrm} />
										</div>
    							</Stack>
								</>
							:
								<>
									<h2 className="">
										<i>{t("zam.cart.items_no")}</i><br />
									</h2>
										<Button variant='primary' onClick={goToTows} className='btn-ico'>
											{t("zam.cart.add_item_no")} {'>>>'}
										</Button>
								</>
								}
						</div>
					</Card.Body>
				</Card>
			</Container>
		}
		</>
	)
};






export const CartItem = React.memo(({item, idx}) => {
	const pllng = IsPLlng();
	//const { user_data } = useAuthContext();
	const { ChgItmInCart, DeleteFromCart, storedConf } = useCardContext();
	const {t} = useTranslation();

	// Cechy of current object
	const cechyString = Object.entries(item.cechy)
		//.map(([key, value]) => `${key}: "${value?.sel[0]}"`)
		.map(([key, value]) => `${pllng ? key : value.name}: "${pllng ? Object.keys(value.sel)[0] : Object.values(value.sel)[0]}"`)
		.join(', ');

	// Check if screen width is MD or larger
	const isMdOrLarger = useMediaQuery({ minWidth: 768 });

	// Change notes
	const ChangeNotk = (not) => {
		ChgItmInCart(
			{
			...item,
			not: not
			},
			"not"
		);
	}
	
	return (
		<>
		{storedConf?.Nazw3 &&
			<div className='tow-row itm-hov shadow rounded-4 p-3' >
				<Row className="align-items-center position-relative">
					<Col className='col-auto'>
						<Badge bg="secondary" className='shadow pill position-absolute top-0 start-0 translate-middle' >
							{idx+1}
						</Badge>
					</Col>
					<Col>
						<strong>
							{/* {storedConf?.Nazw3[9] == 'T' ? <th className="text-start vcentr" ids="Kod">{t('zam.list.code')}</th> :""} */}
							{storedConf?.Nazw3[9] === 'T' ? `${item.Kod || `[NOCODE]`} - ` : ""}
							{storedConf?.Nazw3[10] === 'T' ? `(${item.kat || `[NOCAT]`}) - ` : ""}
							{pllng ? item.Nazw : item.Nazw2}
						</strong>
						<br />
						<small style={{ display: "inline-block", paddingLeft: "20px" }}>
							<i className="text-muted">
								<strong>{t('zam.frmItem.cechy')}:</strong> {cechyString}
							</i>
						</small>	
						<Form.Control 
							as="textarea" 
							id={item?.ID+cechyString.replace(/[\s"'-,]/g, '')}
							placeholder={t('zam.frmItem.wishes')}
							defaultValue={item.not}
							onChange={(e) => ChangeNotk(e.target.value)}
							style={{ backgroundColor: '#ddf3fc' }}
							className='my-2'
						/>
					</Col>
					{/* Prices */}
					{isMdOrLarger && (
						<Col className='col-auto' ststyle={{ display: 'flex', alignItems: 'center' }}>
							{/* Your content for MD and larger screens */}

							<Alert key='price' variant={'secondary'} className='m-1 text-wrap text-md-center rounded-4 shadow ani-shad bg-opacity-50' >
								{/* <strong style={{backgroundColor: 'blue-200'}}>{((storedConf?.Ceny?.NB == "B" ? item.CB : item.CN)*item.qty).toFixed(2)} <i className="text-muted">{item?.wal}</i></strong>  */}
								<strong style={{backgroundColor: 'blue-200'}}>{(item.CB*item.qty).toFixed(2)} <i className="text-muted">{item?.wal}</i></strong> 
								<br />
								<small>
									<i className="text-muted">
										{/* {item.qty} x {(storedConf?.Ceny?.NB == "B" ? item.CB : item.CN)} {item?.wal} */}
										{item.qty} x {item.CB} {item?.wal}
									</i>
								</small> 
							</Alert>
						</Col>
					)}
					<Col className='col-auto' style={{ display: 'flex', alignItems: 'center' }}>
						<ArrowBadge itm={item} size={150}/>
					</Col>
					<Col className='col-auto'>
						<FaTrash 
							className='delete-item-icon' 
							onClick={() => DeleteFromCart(item)}
						/>
						<br />
					</Col>
				</Row>
			</div>
			}
			<br />
		</>
	)
})




export const ArrowBadge = React.memo(({ itm, size=100 }) => {
	const { ChgItmInCart } = useCardContext();

	const [editing, setEditing] = useState(false);
	const [quantity, setQuantity] = useState(itm.qty);
	useEffect(()=>{
		ChgItmInCart({
				...itm,
				qty: parseFloat(quantity).toFixed(2)
			}, 
			"qty"
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[quantity])


	const handleKeyDown = (event) => {
		if (editing) {
			if (event.key === 'Enter') {
				setEditing(false);
				setQuantity(quantity);
			} 
			else if (event.key === 'Escape') {
				setEditing(false);
				setQuantity(itm.qty);
			}
		}
	}

	return (
		<div className="badge-container" style={{ fontSize: size+'%' }}>
			<Button
				variant="outline-light" 
				className="badge-arrow top p-0" 
				onClick={() => setQuantity(parseFloat(quantity) + 1)}
			>
				<IoAddSharp style={{ transform: `scale(${size / 100})` }} />
			</Button>
				<Badge
					bg="secondary"
					className="badge ani-shad"
					tabIndex="0"
					onFocus={() => setEditing(true)}
					onBlur={() => setEditing(false)}
					onKeyDown={handleKeyDown}
				>
					{editing ? 
						<FormControl
							type="number"
							step="0.1"
							min={0.1}
							value={parseFloat(quantity).toFixed(2)}
							onChange={(e) => setQuantity(parseFloat(e.target.value))}
							autoFocus
							onBlur={() => setEditing(false)}
							onKeyDown={handleKeyDown}
							style={{ maxWidth: '80px' }}
						/>
					:
						itm.qty
					}
				</Badge>
			<Button 
				variant="outline-light" 
				className="badge-arrow bottom p-0" 
				onClick={() => setQuantity(parseFloat(quantity) - 1)}
			>
				<IoRemoveSharp style={{ transform: `scale(${size / 100})` }}  />
			</Button>
		</div>
	);
});

export default React.memo(ShopCart);