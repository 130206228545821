// Импортируйте библиотеку crypto-js для AES
import CryptoJS from 'crypto-js';
import { RecaptchaSiteKey } from './global';
import { useTranslation } from 'react-i18next';



/* Function to capitalize first letter */
export const capFrst = (s) => {
  return s[0].toUpperCase() + s.slice(1);
	//return s;
};


/**
 * Brutto to netto
 * 
 * @param {float} brutto 
 * @param {float} Vat 
 * @returns float netto
 */
export const B_to_N = (brutto, Vat=23) =>{
	return parseFloat((brutto * 100 / (100 + Vat)).toFixed(2));
}

/**
 * Netto to brutto
 * 
 * @param {foloat} netto 
 * @param {float} Vat 
 * @returns float brutto
 */
export const N_to_B = (netto, Vat=23) =>{
	return parseFloat((netto * (100 + Vat) / 100).toFixed(2));
}


// Is time to change password? (30 days past)
export const GetPassChgTime = (last) =>{
	return Boolean(Math.floor(((new Date()) - new Date(last)) / 1000 / 60 / 60 / 24 / 30/* 30 days */)) 
}

export function getCurrentDate(separator='-', hms=true){
	let newDate = new Date()
	let date = newDate.getDate().toString().padStart(2, '0');
	let month = (newDate.getMonth() + 1).toString().padStart(2, '0');
	let year = newDate.getFullYear();
	let minutes = newDate.getMinutes().toString().padStart(2, '0');
	let seconds = newDate.getSeconds().toString().padStart(2, '0');
	
	hms = hms ? ` ${newDate.getHours()}:${minutes}:${seconds}` : ``;
	return `${year}${separator}${month}${separator}${date} ${hms}`
}


/**
 * Format date form string (from DB)
 * @param {string} dateFromDB 
 * @returns string
 */
export const formatDate = (dateFromDB, separator='.') => {
  if (!dateFromDB) return null;
	dateFromDB = dateFromDB.replaceAll("-", "");
  // Проверим, является ли дата полной датой и временем (YYYYMMDDHHmm)
  const isFullDateTime = dateFromDB.length === 12;
  // Получим значения года, месяца и дня из строки
  const year = isFullDateTime ? dateFromDB.slice(0, 4) : dateFromDB.slice(0, 4);
  const month = isFullDateTime ? dateFromDB.slice(4, 6) : dateFromDB.slice(4, 6);
  const day = isFullDateTime ? dateFromDB.slice(6, 8) : dateFromDB.slice(6, 8);
  // Соберем дату в формате "28.01.2019"
  return `${day}${separator}${month}${separator}${year}`;
};


/**
 * From date to string to DB
 * @param {date} date 
 * @returns string date
 */
export const dateToDB_ddmmyyyy = (date) => {
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	return `${year}${month}${day}`;
}

export const dateFromDB_toDate = (dateString) => {
	const year = parseInt(dateString.substring(0, 4), 10); // Получаем день (первые две цифры)
	const month = parseInt(dateString.substring(4, 6), 10) - 1; // Получаем месяц (следующие две цифры)
	const day = parseInt(dateString.substring(6), 10); // Получаем год (оставшиеся цифры)
	return new Date(year, month, day); // Создаем объект Date
}

export const b64EncodeUnicode = (str) => {
	// first we use encodeURIComponent to get percent-encoded UTF-8,
	// then we convert the percent encodings into raw bytes which
	// can be fed into btoa.
	return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
		function toSolidBytes(match, p1) {
			return String.fromCharCode('0x' + p1);
	}));
};

export const b64DecodeUnicode = (str) => {
	// Going backwards: from bytestream, to percent-encoding, to original string.
	return decodeURIComponent(atob(str).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
};


// Функция для шифрования
export const encryptData = (data) => {
  const iv = CryptoJS.lib.WordArray.random(16);
  const cipherParams = CryptoJS.AES.encrypt(data, RecaptchaSiteKey, { iv: iv });

  // Кодируем IV и шифротекст в base64, чтобы передать на сервер
  const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
  const encryptedData = cipherParams.toString();

  return {
    iv: ivBase64,
		ivn: iv,
    data: encryptedData
  };
  //return CryptoJS.AES.encrypt(data, apiKeyK, { iv: iv }).toString();
	//CryptoJS.AES.encrypt(data, secretKey, { iv: iv }).toString();
}


/** 
 * Parcing to json from string 
 */
export const parse_to_JS = (obj) => {
	try{
		return JSON.parse(obj);
	}
	catch (e){
		return null;
	};


};


/**
 * Check - is current language is Polish
 * 
 * @returns boolean
 */
export const IsPLlng = () => {
	const { i18n } = useTranslation();
	return i18n.language.toLocaleLowerCase() === 'pl';
}



/**
 * Is request-data is object?
 * 
 * @param {request_data} req_data 
 * @returns boolean
 */
export const isReq_data_Obj = (req_data) => {
	try{
		return ((Object.keys(req_data).length || req_data.length) || 0)
	}
	catch (e){
		return false;
	}
};

/**
 * Is data is non-empty object
 * 
 * @param {object} obj 
 * @returns boolean
 */
export const isNoEmptyObj = (obj) => {
	return ((obj && typeof obj === 'object') && Object.keys(obj).length > 0);
}


export const isEmptyArr = (arr) => {
	//return (arr === undefined || arr.length === 0);
	return arr === undefined || (arr !== null && arr.length === 0);
};

export const isNOEmptyArr = (arr) => {
	//return (Array.isArray(arr) && arr.length > 0);
	return Array.isArray(arr) && (arr !== null && arr.length > 0);
};
export const isNOEmptyArrMoreOne = (arr) => {
	return Array.isArray(arr) && (arr !== null && arr.length > 1);
};





/**
 * Get screen size
 * 
 * @returns breakpoint
 */
export const getScreenSize = () => {
	const screenWidth = window.innerWidth;
	if (screenWidth < 576) {
		return 'sm'; // Маленькая ширина для экранов меньше 576px
	} else if (screenWidth < 992) {
		return 'md'; // Средняя ширина для экранов меньше 992px
	} else if (screenWidth < 1200) {
		return 'lg'; // Большая ширина для экранов больше или равных 992px
	} else {
		return 'xl'; // Большая ширина для экранов больше или равных 992px
	}
};

/**
 * Copy to clipboard
 * 
 * @param {text} val 
 */
export const copyToClipboard = async (val) => {
	if (navigator.clipboard && navigator.clipboard.writeText) {
		navigator.clipboard.writeText(val)
			.then(() => {
				console.log('Order number: ', val, ' copy to clipboard');
			})
			.catch((error) => {
				console.error('Error, when copy to clipboard:', error);
			});
	} else {
		const textarea = document.createElement('textarea');
		textarea.value = val;
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand('copy');
		document.body.removeChild(textarea);
		console.log('Order number: ', val, ' copy to clipboard');
	}
};


/**
 * Function for formatting float value
 * @param {float} value value for formatiing
 * @returns string string of formatted value
 */
export const formatNumber = (value) => {
	const roundedValue = Math.round(value * 100) / 100; // Округляем до 2 знаков после запятой
	const fractionalPart = roundedValue - Math.floor(roundedValue);

	if (fractionalPart === 0) {
		return Math.floor(roundedValue).toString(); // Если нет дробной части, округляем до целого
	} 
	else if (fractionalPart % 1 === 0.0) {
		return roundedValue.toFixed(1).replace('.', ','); // Если дробная часть является нулем, округляем до 1 знака после запятой и меняем точку на запятую
	} 
	else {
		return roundedValue.toFixed(2).replace('.', ','); // Иначе оставляем 2 знака после запятой и меняем точку на запятую
	}

}
	
/**
 * 
 * @param {#HEXCOLOR} color 
 * @param {0.1} opacity 
 * @returns 
 */
export const HexToRgba = (color, opacity=0.1) => {
	if (color == null){
		return 0;
	}

	return `${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, ${opacity}`;
}