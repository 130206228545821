/* HOME  page */
import React from 'react';
import parse from 'html-react-parser';
import { Alert, Container, Row, Col, Stack, Button } from 'react-bootstrap';
/* for translate */
import { useTranslation } from 'react-i18next';

import useAuthContext from '../Context/AuthContext';

import { capFrst } from '../js/func';
import FrmLogin from './Forms/frmLogin';

import { EnvelopeAtFill } from 'react-bootstrap-icons';
import { IsGuestAllow } from '../js/firm_def_configs';
import { useNavigate } from 'react-router-dom';

const Home = (props) => {

	const {t} = useTranslation();
	const navigate = useNavigate();
	const { firmInfo, user_data, IsLoggedUser } = useAuthContext();

	return (
		<>
			<Container>
				{/* <ReCapt3Token token={(v)=>SetToken(v || '')} /> */}

			</Container>

			<br />
			<Container className='mb-10'>
				<Alert variant='primary' className='bg-gradient-primary shadow'>
					<h2 className='text-center '>{capFrst(t('welcome_to_service'))} ({t('app_title')}):</h2>
					{(firmInfo?.Nazw || firmInfo?.Nazw2) && 
						<>
							<h4 className='text-center fst-italic'>
							{firmInfo?.Nazw2 || firmInfo?.Nazw}
							</h4>
						</>
					}

					<Alert variant='warning' className='bg-gradient-warning shadow d-flex align-items-center'>
						<Row className='justify-content-center'>
							<Col className='p-2 d-flex text-wrap align-items-center'>
								<img 
									src={process.env.PUBLIC_URL + '/assets/images/' + (IsLoggedUser() ? '' : 'no') + 'login.png'} 
									style={{ maxHeight: '150px' }}
									className="align-middle img-fluid" 
									alt="" 
								/>&nbsp;
							</Col>
							<Col sm={'auto'} className='p-2 d-flex align-items-center text-sm-center'>
								<strong className='align-middle text-sm-center'>{t('userhi')}:</strong>
							</Col>
						</Row>
					</Alert>

					{IsLoggedUser() ? 
						<>
							<table>
								<tbody>
									<tr>
										<td className='pe-2'><b>{user_data?.User?.UZYT ? t('usrname') : t('firmname')}: </b></td>
										<td> {user_data?.User?.Nazw}</td>
									</tr>
									{(!user_data?.User?.UZYT && user_data?.User?.osoba) &&
									(
										<tr>
											<td className='pe-2'><b>{t('osoba')}:</b> </td>
											<td> {user_data?.User?.osoba}</td>
										</tr>
									)}
									<tr>
										<td className='pe-2'><b>{t('usremail')}:</b> </td>
										<td> {user_data?.User?.Mail}</td>
									</tr>
									{user_data?.User?.Address && 
									(
										<tr>
											<td className='pe-2'><b>{t('usraddr')}:</b> </td>
											<td> {user_data?.User?.Address}</td>
										</tr>
									)}
									{!!user_data?.User?.NIP && 
									(
										<tr>
											<td className='pe-2'><b>{t('usrnip')}:</b> </td>
											<td> {user_data?.User?.NIP}</td>
										</tr>
									)}
								</tbody>
							</table>
						</>
					:
						<>
							<Stack
								className='m-0 p-0'
								direction="horizontal" 
								gap={2}
							>
								<div>
									{/* <span>{t('mustlogin')}.</span>
									<br /> */}
									{/* <strong className='text-danger'> {parse(t('passfromadmin', {mail: firmInfo?.mail ?? ""}))}</strong> */}
										<strong className='text-danger'>
											{parse(t('passfromadminmail'))}
											{firmInfo?.mail &&
												<>
													<EnvelopeAtFill color="#3385ff" />
													{` `}
													<a href={`mailto:${firmInfo?.mail}`}>
														{firmInfo?.mail}
													</a>
												</>
											}
											<Telefon info={firmInfo} />
										</strong>
								</div>

								{(IsLoggedUser() || IsGuestAllow()) && 
									<div className='mx-2 ms-auto'>
										<Button className='ani-btn shadow'
											variant='warning'
											onClick={()=>{navigate('/towary');}}
										>
											{t('form.buttons.oferta')}{` »`}
										</Button>
									</div>
								}

								<div className='ms-auto'>
									{/* If NOT LOGGED */}
									{ !IsLoggedUser() &&
									<>
										<FrmLogin setFrmval={1} className='ani-btn' btns={true} />
									</>
									}
								</div>
							</Stack>
						</>
					}

				</Alert>
				<br />
			</Container>
			{/* If NOT LOGGED */}
			{ !IsLoggedUser() &&
				<OpenFormLogin {...props} sh={props.start} />
			}
		</>
	)
}


const Telefon = ({info}) => {
	return(
		<>
		{(info?.t1 || info?.t2 || info?.fax) &&
			<>
				&nbsp;&nbsp;
				<img src={`${process.env.PUBLIC_URL}/assets/images/icons/tel.png`} alt='phone' />
				{` `}
				<a href={`tel:${(info?.t1 || info?.t2 || info?.fax)}`}>
					{(info?.t1 || info?.t2 || info?.fax)}
				</a>
			</>
		}
		</>
	)
}

const OpenFormLogin = (props) => {
	return (
		<>
			{(props?.start) && 
				<Container className='invisible'> 
					<FrmLogin dbal={props?.dbal} email={props?.email} sh={props?.start}/>
				</Container>
			}
		</>
	)
}

export default Home;