import React from "react";
import { Container } from "react-bootstrap";
import { CookiesApply } from "./CookieConcent";

export const Footer = () => {
	return(
		<Container className="pt-5">
			<footer className="bg-secondary bg-gradient bg-opacity-75 rounded text-center fixed-bottom" style={{marginTop: 'auto!important'}} >
				<CookiesApply />
				<small className="d-flex align-content-center justify-content-center text-center p-1 my-1 border-top" >
					Copyright © 2022 - {new Date().getFullYear()}&nbsp;
					<a className="text-dark" href="https://firmatec.pl" target="_blank" rel="noreferrer" >Firmatec</a>
				</small>
			</footer>
		</Container>
	)
}