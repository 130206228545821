import React, { useEffect, useState } from 'react';

import { Routes, Route, useParams, useSearchParams, Outlet, Navigate } from 'react-router-dom'; 
import ProtectedRoute from './ProtectedRoute';

/* Include pages */
import Home from '../Pages/Home';
import R404 from '../Pages/R404';
//import Docs from '../Pages/Doc_tabs';
import { Contacts } from '../Pages/Contacts';
//import {Calendar} from '../Pages/Calendar'; 
import { useTranslation } from 'react-i18next';
import { TowList } from '../Pages/Towary/Towary';
import { ShopCart } from '../Pages/ShopCart';
import { Alert,  Container } from 'react-bootstrap';
//import { ItmDtl } from '../Pages/Towary/Towar';
import { OrdersList } from '../Pages/Order/OrdersList';
import { PolitykaCookies } from '../Pages/PolitykaCookies';
import { FrmRegister } from '../Pages/Forms/frmRegister';
import { FrmRegisterOk } from '../Pages/Forms/frmRegOk';
import useAuthContext from '../Context/AuthContext';
import { ErrMsg } from '../Pages/Msgs/ErrorMsg';
import { FirmName } from '../js/firm_def_configs';
import { AboutShops } from '../Pages/AboutShops';





function Invoices() {
	return (
		<div>
			<h1>Invoices All</h1>
			<Outlet />
		</div>
	);
}


function Invoice() {
	let params = useParams();

	return <h1>Only one of Invoice: {params.invoiceId}</h1>;
}

function SentInvoices() {
	return <h1>Sent Invoices</h1>;
}


function Prt (get) {
	//let params = useParams();
	const { userId, otherId, anotherId } = useParams();

	console.log(userId, otherId, anotherId)
	console.log("get: " ,get)
	return(
		<Alert variant='primary'>
			<div>
				<h1>Params are {userId}</h1><br />
				<h2>All: {userId} - {otherId} - {anotherId}</h2>
		</div>
		</Alert>
	);
}


function Profile({ name }) {

	const { t } = useTranslation();
	return (
		<Container fluid>
			<Alert variant='warning'>
				<h1>
				{t('navbar.items')}
					Profile me ({name})
				</h1>
				{name ? (
					<h3>
						The <code>name</code> in the query string is &quot;{name}
						&quot;
					</h3>
				) : (
					<h3>There is no name in the query string</h3>
				)}
						
				<hr />
					{/* render if params exists */}
					<Outlet />
			</Alert>
		</Container>
	);
}






const EmptyComponent = () => {
	return (
		<>
			<Navigate to="/" />
		</>
	);
}







/**
 * Get START trigger. Then try to get alias DB and user mail
 * 
 */
const GetStart = () => {
	const { dbal, email } = useParams();
	return (
		<Home start={true} dbal={dbal} email={email} />
	);
}

/**
 * Get REGISTER trigger
 */
const GetReg = (start) => {
	const { dbal, email } = useParams();
	const [showFrm, setShowFrm] = useState(start);
	
	return (
		<>
			<Home />
			<FrmRegister FrmShow={showFrm} dbalias={dbal} e_mail={email} FrmClose={(e)=>{setShowFrm(false)}} />
		</>
	);
}

/**
 * CONFIRM registration
 */
const RegConfirm = (params) => {
	const { dbal, email } = useParams();
	return(
		<>
			<Home />
			<FrmRegisterOk params={params?.get} Frmshow={true} />
		</>
	)
}






const Shop = React.memo(({loaded}) => {
	const { dbal } = useParams();
	const [tryset, setTryset] = useState(false);
	const [resp, setResp] = useState('');

	const { GetFirmCfg, errors_login } = useAuthContext();

	const setFrm = async(frm) => {
		await GetFirmCfg(frm);
		if (errors_login){
			setResp(errors_login?.message);
		}
		else {
			setResp('')
		}
	}

	useEffect(()=>{
		if (dbal === FirmName())
			setTryset(true)
		else if (loaded && !tryset && dbal){
			setTryset(true)	
			setFrm(dbal)
		}
	},[loaded])

	return (
		<>
		{(resp !=='' && errors_login) ?
			<ErrMsg data={errors_login?.message || ""} show={true} modal={true} url={null} logout={true} />
		:
			<Home />
		}
		</>
	)

})







const HeaderRoutes = ({ isUserDataLoaded }) => {

	// eslint-disable-next-line no-unused-vars
	let [searchParams, setSearchParams] = useSearchParams();
	let paramsURL;

	// All params of GET-request -> to array
	searchParams.forEach(function(value, key){
		if (value.length + key.length !== ""){
			paramsURL = {
				...paramsURL,
				[key]: value
			}
		};
		/*console.log("PARAMS_URL ", paramsURL);*/
	});
	

	return (
		<div>
		{/*isUserDataLoaded &&*/
			<Routes>
				<Route exact path="/" element={<Home get={paramsURL} />} />

				{/* Kontakty */}
				<Route path="contacts" element={<Contacts get={paramsURL} />} />

				{/* About */}
				<Route path="about" element={<AboutShops get={paramsURL} />} />

				{/* Polityka cookies */}
				<Route path="polityka-cookies" element={<PolitykaCookies />} />

				{/* For START - route */}
				<Route path="start" element={<GetStart get={paramsURL} start={true} />} >
					{/* <Route path=":dbal" element={<GetStart get={paramsURL} />} /> */}
					<Route path=":dbal/:email?" element={<GetStart get={paramsURL} />} />
				</Route>

				{/* For REGISTER - route */}
				<Route path="register">
					<Route index element={<EmptyComponent />} />
					{/* <Route path=":dbal" element={<GetReg />} start={true} /> */}
					<Route path=":dbal/:email?" element={<GetReg />} start={true}/>
  				<Route path=":dbal/confirm" element={<RegConfirm get={paramsURL} start={false} />} />
				</Route>

				{/* SHOP */}
				<Route path="shop">
					<Route index element={<Home />} />
					<Route path=":dbal" element={<Shop loaded={isUserDataLoaded} />} start={true}/>
				</Route>

				{/* TOWARY */}
				<Route path="towary" element={
					<ProtectedRoute accessBy="towary">
						<TowList get={paramsURL} />
					</ProtectedRoute>
					}
				>
					{/* <Route path=":param" element={<TowList name={'true'} get={paramsURL} />} /> */}
					<Route path=":param/:paramval?" element={<TowList name={'true'} />} />
				</Route>

				{/* SHOPPING CART */}
				<Route path="cart" element={
					<ProtectedRoute accessBy="authenticated">
						<ShopCart get={paramsURL} />
					</ProtectedRoute>
					}
				>
				</Route>


				{/* ORDERS */}
				<Route path="orders/:param?" element={
					<ProtectedRoute accessBy="authenticated">
						<OrdersList get={paramsURL} />
					</ProtectedRoute>
					}
				>
					{/* <Route path=":param" element={<TowList name={'true'} get={paramsURL} />} /> */}
					<Route path=":param/:paramval" element={<Prt name={'true'} />} />
				</Route>


				<Route path="profile" element={<Profile get={paramsURL} />} >
					<Route path=":userId" element={<Prt name={'true'} get={paramsURL} />} />
					<Route path=":userId/:otherId" element={<Prt name={'true'} />} />
					<Route path=":userId/:otherId/:anotherId" element={<Prt name={'true'} />} />
				</Route>


				{/*<Route
					path="/:lang/"
					render={props => <Profile {...props} />}
				/>*/}

				{/*<Route path="/invoices" element={<Invoices />}>
						<Route path=":invoiceId" element={<Invoice />} />
						<Route path="sent" element={<SentInvoices />} />
				</Route>*/}
				{/*<Route path="invoices/:invoiceId" element={<Invoice />} />*/}
				<Route path="invoices" element={<Invoices get={paramsURL} />}>
					<Route path=":invoiceId" element={<Invoice get={paramsURL} />} />
					<Route path="sent" element={<SentInvoices get={paramsURL} />} />
				</Route>

				{/* 
				<Route path="/about1" element={<About1 get={paramsURL}/>} >
					<Route path=":id" element={<About1 get={paramsURL}/>} />
					<Route path=":id/:el" element={<About1 get={paramsURL}/>} />
				</Route>

				<Route path="/calendar" element={<Calendar get={paramsURL}/>} >
					<Route path=":id" element={<Calendar get={paramsURL}/>} />
					<Route path=":id/:el" element={<Calendar get={paramsURL}/>} />
				</Route> */}
				
				<Route path="*" element={<R404 />} />

			</Routes>
		}
		</div>
	)
}

export default HeaderRoutes;
