import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/Arrowbadge.css';
import { Row, Col, Button, Alert, Stack, Modal, Card, Badge, Collapse, Table } from 'react-bootstrap';

import { XCircle, GeoAlt, CardList, DashSquareFill, PlusSquare, CardChecklist } from 'react-bootstrap-icons';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import useCardContext from '../../Context/CartContext';
import { capFrst, formatDate, isNOEmptyArr, isReq_data_Obj } from '../../js/func';
import useAxiosContext from '../../Context/axios_req';
import { FactDtl } from '../FacturaDtl';
import { ErrMsg } from '../Msgs/ErrorMsg';


export const OrderDetails = ({show, ordHDR, order, FrmHide}) => {

	const {t} = useTranslation();
	let navigate = useNavigate();

	const HideFrm = () => {
		FrmHide(false)
	}

	// eslint-disable-next-line no-unused-vars
	const goToTows = () =>{
		navigate('/towary');
	}


	return (
		<Modal
			show={show}
			onHide={HideFrm} 
			backdrop={true}
			size='lg'
		>
			<Modal.Header className="rounded-3 panel-heading bg-primary bg-gradient opacity-75 p-2">
				<strong>
					<>
						{t('zam.frmItem.details')}: {(ordHDR?.NrR) ? ordHDR?.NrR : "[UNKNOWN]"}
					</>
				</strong>
			</Modal.Header>
			<Modal.Body>

				{/* Number and date */}
				<Alert 
					variant='info'
					className='rounded shadow'
				>
					<Row className=''>
						<Col className='d-flex justify-content-start'>
							<i>{t('zam.ord.order')+ `: `} </i>
							<b className='text-muted'>
								&nbsp;{ordHDR?.NrR}
							</b>
						</Col>
						<Col className='d-flex justify-content-end ms-auto px-2'>
							<strong>{formatDate(ordHDR?.DWy)}</strong>
							{/* <strong>{ordHDR?.DWy}</strong> */}
						</Col>
					</Row>
				</Alert>

				<Alert
					variant='warning'
					className='shadow rounded-3'
				>
					<i>{t('client')}{`: `}</i>
					<GetAddress ord={ordHDR} />
				</Alert>

				{/* <div className='mb-3'>
					<AddrDelivery ord={ordHDR} />
				</div> */}
				
				{/* Order header */}
				<OrdHdr ordHDR={ordHDR} />

				<Card className='mb-2'>
					<Card.Header
						className='card-info'
					>
						<strong>
							<CardList />{` `}{t('zam.ord.pos')}
						</strong>
					</Card.Header>
					<Card.Body
						className='bg-info bg-opacity-10'
					>
						{isNOEmptyArr(order) &&
							<>
								{order?.map((el, i) => (
									<ItemDtl key={el.ID + JSON.stringify(el.cechy) + el.Nazw} item={el} idx={i} />
								))}
							</>
						}
					</Card.Body>
				</Card>
				<Stack direction="horizontal" gap={3}>
					<div>

					</div>
					<div>

					</div>
					<div className="ms-auto">
						<Button 
							variant='danger'
							onClick={HideFrm}
							className="ani-shad btn-ico"
						>
							<XCircle />&nbsp;{t('form.buttons.close')}
						</Button>
					</div>
				</Stack>
			</Modal.Body>
		</Modal>

	);
};



const AddrDelivery = React.memo(({ord}) => {
	
	const [addrOpen, setAddrOpen] = useState(false);
	const { t } = useTranslation();
	const invertAddrOpen = ()	=> {
		let inv = !addrOpen
		setAddrOpen(inv)
	}
	return (
		<>
			{(ord?.Os || ord?.Te?.CN || ord?.Te?.CK || ord?.Te?.CM || ord?.Te?.CU) ?
				<div className="pt-3">
					<Card className="mb-2">
						<Card.Header
							className={`bg-warning bg-opacity-25 ${addrOpen ? "bg-gradient " : "shadow "} small-header`}
							onClick={invertAddrOpen}
							aria-controls="coll-dtl"
							aria-expanded={addrOpen}
							as="h6"
						>
							{/* Icon of collapse */}
							<span className="d-inline-flex align-items-center">
								{addrOpen ? <DashSquareFill className="opacity-50" /> : <PlusSquare className="opacity-75" />}&nbsp;
									<strong>&nbsp;{t('zam.frmdelivery.addrdeliv')}</strong>
							</span>
						</Card.Header>

						<Collapse
							in={addrOpen} 
							id="coll-dtl"
						>
							<Card.Body className={`header-condensed rounded-3 ${!addrOpen ? '' : "shadow"} bg-warning bg-opacity-10` }>
								<Row className="align-middle">
									<Col>
										<Table responsive borderless hover className='mb-0'>
											<tbody>
												<tr className={`align-middle ${ord?.Os ? "" : " d-none m-0 p-0"}` }>
													<td className='fst-italic tdminwh'>
														{capFrst(t('osoba'))}:
													</td>
													<td className='fw-bold'>
														{ord?.Os}
													</td>
												</tr>
												<tr className={`align-middle ${ord?.Te?.CN ? "" : " d-none m-0 p-0"}` }>
													<td className='fst-italic tdminwh'>
														{capFrst(t('zam.frmeditaddr.firm'))}:
													</td>
													<td className='fw-bold'>
														{ord?.Te?.CN}
													</td>
												</tr>
												<tr className={`align-middle ${(ord?.Te?.CK + ord?.Te?.CM + ord?.Te?.CU) ? "" : " d-none m-0 p-0"}` }>
													<td className='fst-italic tdminwh'>
														{t('zam.frmdelivery.addrdeliv')}:
													</td>
													<td className='fw-bold'>
														<strong>
															<AddrGeo addr={ord?.Te?.CK + (ord?.Te?.CK ? ', ' : '') + ord?.Te?.CM + (ord?.Te?.CM ? ', ' : '') + ord?.Te?.CU} />
														</strong>
													</td>
												</tr>
												<tr className={`align-middle ${ord?.Te?.CT ? "" : " d-none m-0 p-0"}` }>
													<td className='fst-italic tdminwh'>
														{t('zam.frmeditaddr.tel')}:
													</td>
													<td className='fw-bold'>
														<strong>
															{` `}<TelePhons tel={ord?.Te?.CT} />
														</strong>
													</td>
												</tr>
											</tbody>
										</Table>
									</Col>
								</Row>
							</Card.Body>
						</Collapse>
					</Card>
				</div>
			:
				""
			}
		</>
	)
})


export const OrdHdr = React.memo(({ordHDR}) => {

	const [hdrOpen, setHdrOpen] = useState(false);
	const [fact, setFact] = useState("")
	const { req_data, Get_data } = useAxiosContext();
	const [factDtl, setFactDtl] = useState([])
	const [frmFactShow, setFrmFactShow] = useState(false)
	const [req, setReq] = useState({req:false, to:''});
	const { t } = useTranslation();
	const ShowHideFrmFact = (show) =>{
		if (show)
			ShowFactFrm()
		else
			HideFactFrm()
	}
	const ShowFactFrm = () => {
		setFrmFactShow(true)
	}
	const HideFactFrm = () => {
		setFrmFactShow(false)
		setFactDtl([])
		setFact("")
	}

	const setFactNr = (event) =>{
		const button = event.target.closest('button');
		setFact(button.dataset.bsNr);
	}

	useEffect(()=>{
		if (fact === "")
			return
		const GetFactura = async (fact) =>{
			setReq({req:true, to:'fact_pg'})
			let url = `getdok/zamonline`;
			await Get_data(url, 
				{
					fact: fact,
				});
		}
		GetFactura(fact);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[fact])


	useEffect(() => {
		let getdata = isReq_data_Obj(req_data)
		getdata = (((req.to === 'fact_pg' || req.to === 'get_ord_dtl')) && req_data > 0) || getdata;

		if (getdata && req.req){
			setReq({req:false, to:''})

			if (req.to === 'fact_pg'){
				setFactDtl(req_data)
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[req_data])
		

	// Show form factura
	useEffect(()=>{
		if (isNOEmptyArr(factDtl)){
			ShowFactFrm()
		}
	},[factDtl])
	

	return(
		<>
			<Card className='mb-2'>
				<Card.Header
					className={`bg-secondary ${hdrOpen ? "bg-gradient " : "shadow "} bg-opacity-25 `}
					onClick={() => setHdrOpen(!hdrOpen)}
					aria-controls="coll-hdr"
					aria-expanded={hdrOpen}
					as="h6"
				>

					{/* Icon of collapse */}
					<span className="d-inline-flex align-items-center">
						{hdrOpen ? <DashSquareFill className="opacity-50" /> : <PlusSquare className="opacity-75" />}&nbsp;{` `}
							{/* <strong>&nbsp;{t('zam.frmdelivery.addrdeliv')}</strong> */}
							<strong>{` `} {capFrst(t('zam.frmItem.details'))}</strong>
					</span>

				</Card.Header>

				<Collapse
					in={hdrOpen} 
					id="coll-hdr"
				>
					<Card.Body
						className='bg-secondary bg-opacity-10'
					>

						<Row className="align-middle ">
							<Col>
								<Table responsive borderless hover className='mb-0'>
									<tbody>
										<tr className={`align-middle ${ordHDR?.NB ? "" : " d-none m-0 p-0"}` }>
												<td className='fst-italic tdminwh rounded-3 ani '>
													{capFrst(t('zam.price'))}:
												</td>
												<td className='fw-bold rounded-3 '>
													{t('zam.' + ordHDR?.NB)}
												</td>
											</tr>
											<tr className={`align-middle ${ordHDR?.Fak ? "" : " d-none m-0 p-0"}` }>
												<td className='fst-italic tdminwh rounded-3 ani '>
													{capFrst(t('zam.frmdelivery.fact'))}:
												</td>
												<td className='fw-bold rounded-3 '>
													<span>{ordHDR?.Fak}</span>
													<span className='px-3'>
														<Button 
															variant='outline-primary' 
															className='shadow-sm ani-btn' 
															onClick={(e) => setFactNr(e)}
															size='sm'
															data-bs-nr={ordHDR?.Fak}
														>
															<CardChecklist/>
														</Button>
														<Faktura 
															show={frmFactShow} 
															dtl={factDtl}
															setshow = {(show)=>{ShowHideFrmFact(show)}}
														/>
													</span>
												</td>
											</tr>
											<tr className={`align-middle ${ordHDR?.RejPRO ? "" : " d-none m-0 p-0"}` }>
												<td className='fst-italic tdminwh rounded-3 '>
													{/* {capFrst(t('zam.ord.nr_pack'))}: */}
													Profoma:
												</td>
												<td className='fw-bold rounded-3 '>
													{ordHDR?.RejPRO}
												</td>
											</tr>
											<tr className={`align-middle ${ordHDR?.Defn ? "" : " d-none m-0 p-0"}` }>
												<td className='fst-italic tdminwh rounded-3 '>
													{capFrst(t('zam.ord.nr_pack'))}:
												</td>
												<td className='fw-bold rounded-3 '>
													{ordHDR?.Defn}
												</td>
											</tr>
											<tr className={`align-middle ${ordHDR?.Te?.DO ? "" : " d-none m-0 p-0"}` }>
												<td className='fst-italic tdminwh rounded-3 '>
													{capFrst(t('zam.card.delivery'))}:
												</td>
												<td className='fw-bold rounded-3 '>
													{ordHDR?.Te?.DO}
												</td>
											</tr>
											<tr className={`align-middle ${ordHDR?.zID ? "" : " d-none m-0 p-0"}` }>
												<td className='fst-italic tdminwh rounded-3 '>
													{capFrst(t('zam.ord.nr_ord_client'))}:
												</td>
												<td className='fw-bold rounded-3 '>
													{ordHDR?.zID}
												</td>
											</tr>
											<tr className={`align-middle ${ordHDR?.UW ? "" : " d-none m-0 p-0"}` }>
												<td className='fst-italic tdminwh rounded-3 '>
													{capFrst(t('zam.frmItem.not'))}:
												</td>
												<td className='fw-bold rounded-3 '>
													{ordHDR?.UW}
												</td>
											</tr>
									</tbody>
								</Table>
							</Col>
						</Row>
						{/* Adres dostawy */}
						<Row>
							<Col>
								<AddrDelivery ord={ordHDR} />
							</Col>
						</Row>
					</Card.Body>
				</Collapse>
			</Card>
		</>
	)
})



export const ItemDtl = React.memo(({item, idx}) => {

	const { storedConf } = useCardContext();
	// Cechy of current object
	const cechyString = item?.cech ? Object.entries(item.cech)
		.map(([key, value]) => `${key}: "${value}"`)
		.join(', ') : "";

	const {t} = useTranslation();

	// Check if screen width is MD or larger
	const isMdOrLarger = useMediaQuery({ minWidth: 768 });
	
	return (
		<>
			<div className='tow-row itm-hov shadow rounded-4 p-3' >
				<Row className="align-items-center position-relative">
					<Col className='col-auto'>
						<Badge bg="secondary" className='shadow pill position-absolute top-0 start-0 translate-middle' >
							{idx+1}
						</Badge>
					</Col>
					<Col>
						<strong>
							{/* {storedConf?.Nazw3[9] == 'T' ? <th className="text-start vcentr" ids="Kod">{t('zam.list.code')}</th> :""} */}
							{storedConf?.Nazw3[9] === 'T' ? `${item.Kod || `[NOCODE]`} - ` : ""}
							{storedConf?.Nazw3[10] === 'T' ? `(${item.kat || `[NOCAT]`}) - ` : ""}
							{item.Nazw}
						</strong>
						{cechyString ? 
							<>
								<br />
								<small style={{ display: "inline-block", paddingLeft: "20px" }}>
									<i className="text-muted">
										<strong>{t('zam.frmItem.cechy')}:</strong> {cechyString}
									</i>
								</small>
							</>
						:
							""
						}
						{item?.Opis ? 
							<>
								<br />
								<small style={{ display: "inline-block", paddingLeft: "20px" }}>
									<i className="text-muted">
										<strong>{t('zam.frmItem.not')}:</strong> {item.Opis}
									</i>
								</small>
							</>
						:
							""
						}
					</Col>
					{/* Prices */}
						<Col className={`d-flex ` + (isMdOrLarger ? `col-auto` : `col-12 justify-content-end`)} style={{ alignItems: 'center' }}>
							{/* Your content for MD and larger screens */}

							<Alert key='price' variant={'secondary'} className='m-1 text-wrap text-md-center rounded-4 shadow ani-shad bg-opacity-50' >
								{/* <strong style={{backgroundColor: 'blue-200'}}>{((storedConf?.Ceny?.NB == "B" ? item.CB : item.CN)*item.qty).toFixed(2)} <i className="text-muted">{item?.wal}</i></strong>  */}
								<strong style={{backgroundColor: 'blue-200'}}>{(item.CB*item.il).toFixed(2)} <i className="text-muted">{item?.Wal}</i></strong> 
								<br />
								<small>
									<i className="text-muted">
										{/* {item.qty} x {(storedConf?.Ceny?.NB == "B" ? item.CB : item.CN)} {item?.wal} */}
										{item.il} x {item.CB} {item?.Wal}
									</i>
								</small> 
							</Alert>
						</Col>
				</Row>
			</div>
			<br />
		</>
	)
})






/**
 * Render address of client with phone-numbers
 * 
 * @param {object} props 
 * @returns 
 */
const GetAddress = React.memo(({ord}) => {

	const ClName = ord?.nazw + (ord?.nazw ? (ord?.Os ? " (" + ord?.Os + ") " : "") : ord?.Os);
	const addr = ord?.KodP + (ord?.KodP ? ', ' : '') + ord?.Mias + (ord?.Mias ? ', ' : '') + ord?.Ulic;

	const tel = ord?.T1;
	//const tel = "+375292140303";
	
	return (
		<>
			<strong>{ClName}</strong>
			{/* Telephons */}
				{tel &&
					<>
						<br />
						{` `}<TelePhons tel={tel} />
					</>
				}
	 		{/* Coordinates */}
	 		{/* {obj["lat"] + obj["lon"] ?  */}
				{addr &&
					<>
						<br />
						<AddrGeo addr={addr} />
					</>
				}
		</>
	);
})



/**
 * Address with geolink
 */
const AddrGeo = React.memo(({addr})=>{
	return(
		<>
			<strong>
				<GeoAlt 
					color="red"
					className='fw-bolder'
					//size={15}
				/>
			</strong>
			{/* <a href={"https://www.google.com/maps/place/" + obj['lat'] + "," + obj['lon']} target="_blank" rel="noreferrer" > */}
			<small>
				&nbsp;<a href={"https://www.google.com/maps/place/" + addr} target="_blank" rel="noreferrer" >
					{addr}
				</a>
			</small>
		</>
	)
})


/**
 * Render telephones numbers with icon
 * 
 * @param {teleph1, teleph2} props 
 * @returns 
 */
const TelePhons = React.memo(({tel}) => {
	return (
		<small>
			{(tel !== null && tel !== undefined && tel.length) ?
					<>
						<img src={process.env.PUBLIC_URL + "/assets/images/icons/tel.png"} alt="Telephone" />
						&nbsp;<a href={"tel:" + tel}>{tel}</a>
					</>
				:
					null
			}
			{/* {(tel2 !== null && tel2.length) ?
					<>
						, <a href={"tel:" + tel2}>{tel2}</a>
					</>
				:
					null
			} */}
		</small>
	);
})



export const Faktura = React.memo(({show, dtl, setshow}) => {
	const close = () => {
		setshow(false)
	}
	if (dtl !== undefined && isNOEmptyArr(dtl)) {
		switch (dtl[0]['doc_type'].toUpperCase()) {
			case 'F':
				/* Return detail of factura modal */
				return(
					// <FactDtl data={dtl} show={show} onHide={() => HideFrmDtl()}/>
					<FactDtl show={show} dtl={dtl} setshow={(sh) => close(sh)}/>
				);
			case 'Z':
				/* Return detail of zlecenie modal */
				return(
					<></>
					// <ZlecDtl data={dtl} show={show} onHide={() => HideFrmDtl()}/>
				);
			default:
				return(
					<ErrMsg data={"Niepravidlowy typ dokumentu: <b>" + dtl[0]['doc_type'] + "</b>"} show={show} />
				);
		}
	}
	// return(
	// 	<>
	// 	</>
	// )
})

export default React.memo(OrderDetails);