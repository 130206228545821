//https://www.npmjs.com/package/react-cookie-consent
//https://github.com/Mastermindzh/react-cookie-consent#readme
import CookieConsent/*, { getCookieConsentValue, resetCookieConsentValue }*/ from "react-cookie-consent";
import { useTranslation } from "react-i18next";

const CookieConsName = "firmatec_CookieConsent";

export const CookiesApply = () => {

	const { t } = useTranslation()
	
	return(
		<>
			<CookieConsent
				//debug={true}
				//disableStyles={true}
				location="bottom"
				cookieName={CookieConsName}
				containerClasses="text-wrap alert alert-light rounded border-0  fade show"
				buttonClasses="btn rounded-3 shadow btn-primary fw-bold bg-info " 
				acceptOnScroll={true}
				acceptOnScrollPercentage={50}
				buttonText={t('cookies.accept')}//"Sure man!!"
				//overlay={true}
				//expires={365}
			>
				<div className="text-start">
					W celu świadczenia usług na najwyższym poziomie,
					w tym w sposób dostosowany do indywidualnych potrzeb, 
					w ramach naszej witryny stosujemy pliki cookies. 
					Korzystanie z witryny oznacza wyrażenie zgody na ich stosowanie zgodnie z aktualnymi ustrawieniami przeglądarki.
					<br />
					Więcej szczegółów w naszej {` `}
						<a href="/polityka-cookies" target="_blank" style={{color: "#ffffcc"}}>Polityce Cookies</a>, {` `} 
						<a href="https://www.firmatec.pl/Regulamin.htm" target="_blank" style={{color: "#ffffcc"}} rel="noreferrer">Regulaminach</a>, {` `}
						<a href="https://www.firmatec.pl/PolitykaPrywatnosciFirmatec.htm" target="_blank" style={{color: "#ffffcc"}} rel="noreferrer">Polityce prywatności</a>.
				</div>
			</CookieConsent>
		</>
	)
}