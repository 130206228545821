/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Modal, Stack } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";
import useAuthContext from "../../Context/AuthContext";
import { isReq_data_Obj } from "../../js/func";
import useAxiosContext from "../../Context/axios_req";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { RecaptchaSiteKey } from "../../js/global";
import { initRecaptcha } from "../../Components/ReCaptcha3";


export const FrmRegisterOk = ({params}, Frmshow) => {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const { firmConfig, GetFirmCfg, setReg_data } = useAuthContext();
	const { req_data, Put_data, req_dataerr, setReq_dataerr } = useAxiosContext();

	const [show, setShow] = useState(Frmshow);
	const [load, setLoad] = useState(false);

	const [retMsg, setRetMsg] = useState(null);
	const [req, setReq] = useState({req:false, to:''});

	// Loading firm-data and firm-info
	useEffect(()=>{
		const GetParams = async () =>{
			await GetFirmCfg(params?.idfirmy);
			setLoad(true);
		}

		if (!isReq_data_Obj(firmConfig)){
			if (params?.idfirmy){
				// Запросить конфигурацию фирмы из БД
				GetParams();
			}
		}
		else
			setLoad(true)
	},[])


	// Update, if error - goto Home page
	useEffect(()=>{
		if (show && req_dataerr?.err && req_dataerr?.url !== '/'){
			setReq_dataerr((prev) => ({
				...prev,
				url: '/'
			}))
		}
	},[req_dataerr])


	useEffect(()=>{

		const PutDataToCheck = async (token) =>{
			setReq({req:true, to:'VeryfReg'});
			await Put_data("auth/" + params?.idfirmy + "/" + params?.usr + "/" , 
			{
				...params,
				token: token
			});
		}

		// Waiting for loading
		if(load && Frmshow){
			ReactRecaptcha3.getToken().then(token => {
				PutDataToCheck(token);
				return;
			}, error => {
				// handle error here
				console.log(error)
			})
			return;
		}
	},[load])



	useEffect(() => {
		let getdata = ((req.to === 'VeryfReg') && req_data > 0) || isReq_data_Obj(req_data);
		if (show)
			initRecaptcha(true);

		if (show && getdata && req.req){
			setReq({req:false, to:''})
			if (req.to === 'VeryfReg'){
				setRetMsg(req_data);
				setReg_data(null);
			}
		}
	},[show, req_data])


	// Hide form
	const hideFrm = () => {
		initRecaptcha(false);
		setShow(false);
		setLoad(false);
		navigate('/');
	}


	return (
		<>
			<Modal
				show={show && !req_dataerr?.err}
				centered
			>
				<Modal.Header className="p-2 px-3 bg-dark bg-gradient bg-opacity-10">
					{retMsg ?
						t('zam.frmcmpl.success')
					:
						<i>{t('form.reg.titl_wait')}</i>
					}
				</Modal.Header>
				<Modal.Body className="bg-dark bg-gradient bg-opacity-10">
					{retMsg ?
						<>
							<Alert
								variant="success"
								className="rounded-4 shadow"
							>
								<div className="text-center fw-bolder">
									{parse(retMsg || '')}
								</div>
							</Alert>
						</>
					:
						<>
							<Alert
								variant="danger"
								className="rounded-4 shadow"
							>
								<div className="text-center fw-bolder">
									<strong>
										<span className="text-muted">{t('form.reg.titl_wait')}</span> 
									</strong>
								</div>
							</Alert>
						</>
					}

					<Stack
						direction="horizontal" 
						gap={3}
					>
						<div className="tow-btn">

						</div>
						<div className="tow-btn ms-auto ">
							<Button 
								variant="primary" 
								className="ani-shad shadow btn-ico"
								onClick={() => hideFrm()}
							>
								<Check2Circle className="ani-btn"/>  &nbsp;{t('form.buttons.close')}
							</Button>
						</div>
					</Stack>
				</Modal.Body>
			</Modal>
		</>
	);
}