import React, { useState } from "react";
import { Modal, Alert, Col, Row, Container, Button } from "react-bootstrap";
import parse from 'html-react-parser';
import {ExclamationTriangleFill, XCircleFill} from "react-bootstrap-icons";
import { HomeBtn } from "../Layouts/Buttons";
import { useNavigate } from 'react-router-dom';
import useAxiosContext from "../../Context/axios_req";
import useAuthContext from "../../Context/AuthContext";
import { useTranslation } from "react-i18next";

/* ERROR MESSAGE */
const ErrMsg = (props) => {

	const [showFrm, setShowFrm] = useState(props.show)

	const { logout } = useAuthContext();

	const { t } = useTranslation();
	
	const { setReq_dataerr } = useAxiosContext();

	const handleClose = () => {
		setReq_dataerr({err: false});
		setShowFrm(false);

		if (props?.logout){
			logout();
		}

		if (props?.url){
			NavPage()
		}
	};

	let navigate = useNavigate();

	async function NavPage (event) {
		// 👇️ redirect
		navigate(props.url || '/');
	}

	return (
		<Modal
			className="fade"
			show = {showFrm}
			backdrop="static"
			//size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header className="rounded-3 panel-heading bg-danger bg-gradient opacity-75 p-2">
				<div className="d-flex flex-row">
					<div className="d-flex align-items-center"><ExclamationTriangleFill />&nbsp;</div>
					<div className="d-flex align-items-center"><b>Błąd</b></div>
				</div>
			</Modal.Header>

			<Modal.Body className="p-3">
				{/* Error message if exist */}
				<Alert
					variant="danger"
					show={(props.data !== undefined)}
					className="mb-0"
				>
					<div className="d-flex p-2 bd-highlight">
						<div className="">{parse(props.data.replaceAll("\n", "<br />").replaceAll("____", "<b>").replaceAll("\\___", "</b>"))}</div>
					</div>
				</Alert>
			</Modal.Body>

			<Modal.Footer className="p-1">
				<Container fluid className="p-1 m-0">
					<Row>
						<Col>
						
						</Col>
						<Col className="d-flex justify-content-end">
							{!props?.modal ? 
								<div onClick={handleClose}>
									<HomeBtn /> 
								</div>
							: 
								<Button 
									variant="secondary" 
									onClick={handleClose} 
									className='d-flex align-items-center'
								>
									<XCircleFill />&nbsp; {t('form.buttons.close')}
								</Button>
							}
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};

export {ErrMsg};