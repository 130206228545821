import React, { useState } from "react";
import { Alert, Button, Modal, Stack } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";


export const FrmOrderComplete = ({data, setFrmOrdComplShow}) => {
	const { t } = useTranslation();

	const hideFrm = () => {
		setFrmOrdComplShow(false)
	}

	return (
		<>
			<Modal
				//size="sm"
				show={data.show}
				centered
			>
				<Modal.Header className="p-2 px-3 bg-dark bg-gradient bg-opacity-10">
					{t('zam.frmcmpl.success')}
				</Modal.Header>
				<Modal.Body className="bg-dark bg-gradient bg-opacity-10">
					<Alert
						variant="success"
						className="rounded-4 shadow"
					>
						<div className="text-center fw-bolder">
							{t('zam.frmcmpl.success_cr')}
						</div>
						<br />
						<strong>
							<span className="text-muted">{t('zam.frmcmpl.ord_num')}:</span> <strong className="mark fw-bolder">{data.ordnum}</strong>
						</strong>
					</Alert>
					<Stack
						direction="horizontal" 
						gap={3}
					>
						<div className="tow-btn">

						</div>
						<div className="tow-btn ms-auto ">
							<Button 
								variant="primary" 
								className="ani-shad shadow btn-ico"
								onClick={() => hideFrm()}
							>
								<Check2Circle className="ani-btn"/>  &nbsp;{t('form.buttons.close')}
							</Button>
						</div>
					</Stack>
				</Modal.Body>
			</Modal>
		</>
	);
}