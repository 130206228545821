/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Container, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import useAxiosContext from "../../Context/axios_req";
import { HexToRgba, dateFromDB_toDate, dateToDB_ddmmyyyy, formatDate, isNOEmptyArr, isReq_data_Obj } from "../../js/func";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { ChatRightText, ListTask, SortAlphaDown, SortAlphaDownAlt } from "react-bootstrap-icons";
import { cntr_horiz } from "../../App";
import OrderDetails from "./OrderDetails";
import { PickDates } from "./DateInterval";

const placement = "bottom";

export const OrdersList = React.memo(({get}) => {

	const { param, paramval } = useParams();

	const navigate = useNavigate();
	const { t } = useTranslation();

	const { req_data, Get_data } = useAxiosContext();
	const [config, setConfig] = useState({
		//itmgroup: "",
		//itmprod: "",
		sort: "DWy|desc",
		search: "",
		date_from: dateToDB_ddmmyyyy(new Date(new Date().getFullYear(), 0, 1)),
		date_to: dateToDB_ddmmyyyy(new Date()),
		page: (get?.page || 1), 
		perpage: 20, 
		total: 1
	})
	const [ordList, setOrdList] = useState({});
	const [pagData, setPagData] = useState({});
	const [showFrm, setShowFrm] = useState(false);
	const [orderDtl, setOrderDtl] = useState({});
	const [selOrd, setSelOrd] = useState({});

	const [init, setInit] = useState(1)
	const [req, setReq] = useState({req:false, to:''});
	const url = "zam/orders/";
	const [alertshow, setAlertShow] = useState(true);
	const [sort, setSort] = useState({
		col: 'DWy',
		dir: 'desc',
		ico: <SortAlphaDownAlt color="royalblue" title={'desc'} size={18} />
	});

	const ChgSort = (e) => {
		const col = (e.currentTarget.getAttribute('ids'));
		const dir = ((sort.col === col && sort.dir === 'asc') ? 'desc' : 'asc');
		setSort((prev) => ({
			...prev,
			col: col,
			dir: dir,
			ico: dir === "asc" ? <SortAlphaDown color="royalblue" title={dir} size={18} /> : <SortAlphaDownAlt color="royalblue" title={dir}  size={18}/>
		}));
	}

	// Change sort 
	useEffect(() => {
		if (sort.col && !init){
			setConfig((prevState) => ({
				...prevState,
				sort: `${sort.col}|${sort.dir}`
			}));
		}
	}, [sort])


	// Show order-details form
	const ShowOrdDtl = (id='') => {
		const selectedOrder = ordList.find(order => order?.ID === id); // Находим заказ по ID
		if (selectedOrder) {
			//console.log(selectedOrder);
			setSelOrd(selectedOrder); // Обновляем состояние выбранного заказа
		}
		
		const GetOrdDtl = async () =>{
			setReq({req:true, to:'get_ord_dtl'})
			await Get_data(url, 
				{
					id: id,
				});
		}
		GetOrdDtl();
	}


	const ShowFrmDtl = (hide) => {
		setShowFrm(hide)
	}

	// Change dates-interval
	const ChgDates = (type, date) => {
		setConfig((prevState) => ({
			...prevState,
			[type]: dateToDB_ddmmyyyy(date)
		}));
	}


	useEffect(() => {
		const GetPageOrders = async () =>{
			setReq({req:true, to:'ords_pg'})
			await Get_data(url, 
				{
					//ff: "dd",
					page: (config?.page ? config.page : 1),
					//itmgroup: (config?.itmgroup ? config.itmgroup : ""),
					//itmprod: (config?.itmprod ? config.itmprod : ""),
					date_from: (config?.date_from ? config.date_from : ""),
					date_to: (config?.date_to ? config?.date_to : ""),
					sort: (config?.sort ? config.sort : ""),
					search: (config?.search ? config.search : ""),
					perpage : (config?.perpage ? config.perpage : null),
					init: init
					//page: 4
				});
		}
		GetPageOrders();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[config])


	useEffect(() => {
		let getdata = isReq_data_Obj(req_data)
		getdata = (((req.to === 'ords_pg' || req.to === 'get_ord_dtl')) && req_data > 0) || getdata;

		if (getdata && req.req){
			setReq({req:false, to:''})

			if (req.to === 'ords_pg'){
				setOrdList(req_data?.data);
				const {data, ...paginate} = req_data;
				// Replace page for current page
				navigate(`?page=${req_data?.current_page}`, { replace: true });
				setPagData(paginate);
				if (init){
					setInit(0);
				}
			}
			if (req.to === 'get_ord_dtl'){
				if (req_data){
					setOrderDtl(req_data?.data)
					ShowFrmDtl(true)
				}
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[req_data])
	
	
	return (
		<Container fluid>
			<Card className='shadow rounded-4  '>
				<Card.Header className={`${cntr_horiz} card-info`} style={{ display: 'flex', alignItems: 'center' }}>
					<OverlayTrigger
						placement={placement}
						overlay={
							<Tooltip id={`tooltip-${placement}`}>
								{t('navbar.orders')}
							</Tooltip>
						}
					>
						<strong style={{ display: 'flex', alignItems: 'center' }}>
							{/* <Building color="royalblue" /> &nbsp;  */}
							<ListTask /> &nbsp;
							{t('navbar.orders')}
						</strong>
					</OverlayTrigger>
				</Card.Header>

				{/* Context */}
				<Card.Body className='' >
					<PickDates 
						date_from={dateFromDB_toDate(config?.date_from) || null} 
						date_to={dateFromDB_toDate(config?.date_to) || null} 
						ChangeDatesConfig={(type, date) => ChgDates(type, date)}
					/>
					{isNOEmptyArr(ordList) ?
						<>
							{/* <ItmRows items={ordList} sort={sort} ChgSortOrd={() => ChgSort()} ShowOrdDtl={(id) => {ShowOrdDtl(id)}}/> */}
							<ItmRows items={ordList} sort={sort} ChgSortOrd={() => ChgSort()} ShowOrdDtl={(id) => {ShowOrdDtl(id)}}/>
							{/* <Paginate data={ordList}/> */}
							<PaginationControl
								page={pagData?.current_page}
								between={4}
								total={pagData?.total}
								limit={pagData?.per_page}
								changePage={(page) => {
									setConfig((prevState) => ({
										...prevState, 
											page: page
									}));
								// Обновление адресной строки с новым номером страницы
								navigate(`?page=${page}`);}}
								ellipsis={1}
								//last={1}
							/>
							{/* Modal with details of selected order */}
							<OrderDetails show={showFrm} ordHDR={selOrd} order={orderDtl} FrmHide={(hide) =>ShowFrmDtl(hide)} />
						</>
					:
						alertshow ? 
							<Alert variant="danger" className="shadow fade in" dismissible onClose={() => setAlertShow(false)}/**/	>
								<strong>{t("zam.ord.no_items")}!</strong>
							</Alert>
						:
							<Button variant='primary' onClick={()=>{navigate('/towary');}} className='btn-ico'>
								{t("zam.cart.add_item_no")} {'>>>'}
							</Button>
					}
				</Card.Body>
			</Card>
		</Container>
	)
});



const ItmRows = ({items, sort, ChgSortOrd, ShowOrdDtl}) =>{

	const { t } = useTranslation();	


	// Constants of stans
	const stan = (val, opacity='0.4') => {
		const stanVals = [
			[t('zam.ord.statuses.received') , 'ffff00'],
			[t('zam.ord.statuses.in_progress'), 'ffffff'],
			[t('zam.ord.statuses.ready'), '00ff00'],
			[t('zam.ord.statuses.completed'), 'a6caf0'],
			[t('zam.ord.statuses.rejected'), 'ff0000']
		];
		const rgb = () => {
			return `rgba(${parseInt(stanVals[val][1].substring(0, 2), 16)}, 
									${parseInt(stanVals[val][1].substring(2, 4), 16)}, 
									${parseInt(stanVals[val][1].substring(4, 6), 16)}, 
									${opacity})`;
		};
		return [stanVals[val][0], rgb()];
	};


	const class_sort = (ids) => {
		return ` cpointer` + (sort.col === ids ? ` bg-primary bg-gradient bg-opacity-10 rounded` : ``)
	}

	/**
	 * Render Tooltip on page
	 * @param {string} props 
	 * @returns Tooltop
	 */
	const RenderTooltip = (props) => (
		<OverlayTrigger
			placement={placement}
			overlay={
				<Tooltip id={`tooltip-${placement}`}>
					{props.msg}
				</Tooltip>
			}
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{props.msg.slice(0,(props.len || 14))}{'...'}
			</div>
		</OverlayTrigger>
	);



	const ShowFrmOrdDtl = (id) => {
		ShowOrdDtl(id);
	}


	return(
		<>
			<Table striped hover responsive style={{backgroundColor:"white"}} className="table-condensed">
				<thead className="table-active table-header-condensed" /*onClick={e => sort_arr(e.target)}*/>
					<tr className="cdefault">
						<th 
							className={`vcentr text-center ${class_sort('NrR')}`}
							ids="NrR"
							onClick={(e) => ChgSortOrd(e)}
						>
							{t('zam.ord.nr')}{` `}
							{sort.col === 'NrR' && sort.ico}
						</th> 
						<th 
							className={`vcentr text-center ${class_sort('DWy')}`}
							ids="DWy"
							onClick={(e) => ChgSortOrd(e)}
						>
							{t('zam.ord.date')}{` `}
							{sort.col === 'DWy' && sort.ico}
						</th> 
						<th className="vcentr" ids="Alias">{t('zam.ord.uz')}</th> 
						<th 
							className={`vcentr text-center ${class_sort('Wart')}`}
							ids="Wart"
							onClick={(e) => ChgSortOrd(e)}
						>
							{t('zam.ord.wart')}{` `}
							{sort.col === 'Wart' && sort.ico}
						</th> 
						<th className="vcentr text-center" ids="Wal">{t('zam.wal')}</th> 
						<th className="vcentr" ids="etap">{t('zam.ord.etap')}</th> 
						<th className="vcentr" ids="UW">{t('zam.frmItem.not')}</th> 
						<th 
							className={`vcentr text-center ${class_sort('Stan')}`} 
							ids="Stan"
							onClick={(e) => ChgSortOrd(e)}
						>
							{t('zam.list.stan')}{` `}
							{sort.col === 'Stan' && sort.ico}
						</th> 
						<th>
							{/* Empty th for icon */}
						</th>
					</tr>
				</thead>
				<tbody>
					{items?.map((v, i) => (
						<tr 
							key={v.ID}
							className="rounded"
						>
							{/* Number */}
							<td
								className="vcentr rounded text-center"
								style={{ backgroundColor: stan(v.Stan)[1]}}
							>
								{v.NrR}
							</td>
							{/* Date */}
							<td
								className="vcentr text-center"
							>
								{formatDate(v.DWy)}
							</td>
							{/* Alias */}
							<td
								className="vcentr "
							>
								{v.Alias}
							</td>
							{/* Price */}
							<td
								className="vcentr text-center"
							>
								{v.Wart}
							</td>
							{/* Waluta */}
							<td
								className="vcentr text-center "
							>
								{v.Wal}
							</td>
							{/* Etap */}
							<td
								className="vcentr rounded"
								style={{ backgroundColor: `rgba(${HexToRgba(v?.etcolor, 0.1)})` }}
							>
								<div >
								{v.etap?.length > 15 ?
									<RenderTooltip msg={v?.etap} />
								:
									v.etap
								}
								</div>
							</td>
							{/* Uwagi */}
							<td
								className="vcentr"
							>
								{v?.UW?.length > 15 ?
									<RenderTooltip msg={v.UW} />	
								: 
									v.UW
								}
							</td>
							{/* Stan */}
							<td 
								className="vcentr text-center"
								//style={{ backgroundColor : '#' + stan(v.Stan)[1] }} 
							>
								{stan(v.Stan)[0]}
							</td>
							{/* Button */}
							<td className="vcentr m-1 p-0">
								<ChatRightText 
									//color={stan(v.Stan, 1)[1]}
									style={{ backgroundColor : stan(v.Stan,0.4)[1] }} 
									size={25} 
									className="ani-btn rounded calias" 
									id={v?.ID}
									onClick={() => ShowFrmOrdDtl(v.ID)}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	)
};

