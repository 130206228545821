/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Alert, Button, Card, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { PersonPlusFill, XCircleFill } from 'react-bootstrap-icons';
import { useForm } from "react-hook-form";
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../Context/AuthContext';
import { isNOEmptyArr, b64EncodeUnicode } from '../../js/func';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { useNavigate } from 'react-router-dom';
import { initRecaptcha } from '../../Components/ReCaptcha3';


export const FrmRegister = ({FrmShow=true, dbalias='', e_mail='', FrmClose}) => {
	
	let navigate = useNavigate();
	const { t } = useTranslation();
	const { firmConfig, GetFirmCfg, firmInfo, reg_data, setReg_data, User_Register, errors_reg, setErrors_reg, spin } = useAuthContext();

	useEffect(() => {
		if (FrmShow){
			if (dbalias){
				// Запросить конфигурацию фирмы из БД
				GetFirmCfg(dbalias)
				setValue("dbal", dbalias)
			}
			else
				setValue("dbal", firmInfo?.IDFirmy)

			initRecaptcha(true);
		}
		else{
			setReg_data('');
		}
	},[FrmShow])


	const RegisterHide = () => {
		FrmClose();
		initRecaptcha(false);
		if (reg_data)
			navigate('/');
	}

	const {
		register,
		trigger,
		watch,
		getValues,
		setValue,
		formState: {
			errors,
			isValid,
		},
		handleSubmit,
	} = useForm({
		mode: "all",
		defaultValues: {
			dbal: firmInfo?.IDFirmy, 
			mail: e_mail || "",
		}
	});

	const Trig = async () =>{
		await trigger();
	}

	const apiKey = process.env.REACT_APP_SITE_KEY;
	const custidw = process.env.REACT_APP_D_RELEASE;

	useEffect(()=>{
		if (!spin)
			Trig();
	},[spin])


	useEffect(()=>{
		if (FrmShow){
			if (!(isNOEmptyArr(firmConfig?.Nazw3) && firmConfig?.Nazw3[3] === 'T'))
				setErrors_reg({message: t('nofirmorusrreg')});
			else 
				setErrors_reg();
		}
	},[firmConfig?.Nazw3])


	// Submit form register
	const onSubmit = async (data) => {
		//data.pass = bcrypt.hashSync(data.password, bcrypt.genSaltSync());
		data.pass = b64EncodeUnicode(data.password);
		/*const {password, re_password, ...post_data} = data;
		console.log(post_data);*/
		delete data.password
		delete data.re_password;

		// Create new user
		await ReactRecaptcha3.getToken().then(token => {
			data={...data, token: token}
			User_Register(data);
			return;
		}, error => {
			// handle error here
			console.log(error)
		})
	}
	

	const chgDBAlias = async () => {
		const dbAlias = watch("dbal", ""); // Параметр - имя поля
		if (dbAlias) {
			// Запросить конфигурацию фирмы из БД
			GetFirmCfg(dbAlias)
		}
	}

	return (
		<>
		{!spin && FrmShow &&
			<Modal 
				show={FrmShow} 
				onHide={RegisterHide}
				/*{...props} */
				backdrop="static" 
				size={reg_data ? '': 'lg'}
				//centered 
			>

				<Modal.Header closeButton className='alert bg-info bg-gradient bg-opacity-25' style={{padding:10}}>
					<strong className='d-flex align-items-center'>
						<PersonPlusFill /> &nbsp;
						{t('form.reg.title')}
					</strong>
				</Modal.Header>

				<Form 
					onSubmit={handleSubmit(onSubmit)}
				>
					<Modal.Body>
						
					{(isNOEmptyArr(firmConfig?.Nazw3) && firmConfig?.Nazw3[3] === 'T' && !spin && !reg_data) ?
						<>
						{/* AliasDB */}
						<Row className="mb-3">
							<Col sm={12} md={12}>
								<FloatingLabel controlId="frmReg.labelAliasDB" label={t('form.login.alias.text')+":"}>
									<Form.Control
										className={(errors?.dbal && "is-invalid") || "is-valid"}
										type='input'
										size="lg" 
										{...register("dbal", {
											required: {
												value: true,
												message: t('form.login.errors.req'),
											},
											minLength: {
												value: 3,
												message: t('form.login.errors.min', {min_nr: 3}),
											},
										})}
										//defaultValue={firmInfo?.IDFirmy}
										disabled={!!firmInfo?.IDFirmy}
										onBlur={chgDBAlias} // Добавляем обработчик потери фокуса
									/>
									<Form.Text className={errors?.dbal ? "invalid-feedback" : "text-muted"}>
										<small>
											{(errors?.dbal && (errors?.dbal?.message || "Error!")) || t('form.login.alias.text-label')}
										</small>
									</Form.Text>
								</FloatingLabel>
							</Col>

							{/* Alias */}
							{/* <Col sm={12} md={6}>
								<FloatingLabel controlId="frmReg.labelalias" label={t('form.reg.alias.text')+":"}>
									<Form.Control
										className={(errors?.Alias && "is-invalid") || "is-valid"}
										type='input'
										{...register("Alias", {
											required: {
												value: true,
												message: t('form.login.errors.req'),
											},
											minLength: {
												value: 3,
												message: t('form.login.errors.min', {min_nr: 3}),
											},
										})}
										//defaultValue={form.Alias}
									/>
									<Form.Text className={errors?.Alias ? "invalid-feedback" : "text-muted"}>
										<small>
											{(errors?.Alias && (errors?.Alias?.message || "Error!")) || t('form.reg.alias.text-label')}
										</small>
									</Form.Text>
								</FloatingLabel>
							</Col> */}
						</Row>
						<Row className="mb-3">
							{/* Nazwa */}
							<Col sm={12}>
								<FloatingLabel controlId="frmReg.labelnazw" label={t('form.reg.nazw.text')+":"}>
									<Form.Control
										className={(errors?.Nazw && "is-invalid") || "is-valid"}
										type='input'
										{...register("Nazw", {
											required: {
												value: true,
												message: t('form.login.errors.req'),
											},
											minLength: {
												value: 3,
												message: t('form.login.errors.min', {min_nr: 3}),
											},
										})}
										//defaultValue={form.Nazw}
									/>
									<Form.Text className={errors?.Nazw ? "invalid-feedback" : "text-muted"}>
										<small>
											{(errors?.Nazw && (errors?.Nazw?.message || "Error!")) || t('form.reg.nazw.text-label')}
										</small>
									</Form.Text>
								</FloatingLabel>
							</Col>
						</Row>
						<Row className="mb-3">
							{/* NIP */}
							<Col sm={12} md={6}>
								<FloatingLabel controlId="frmReg.labelnip" label={t('usrnip')+":"}>
									<Form.Control
										className={(errors?.NIP && "is-invalid") || "is-valid"}
										type='input'
										{...register("NIP")}
										//defaultValue={form.NIP}
									/>
									<Form.Text className={errors?.NIP ? "invalid-feedback" : "text-muted"}>
										<small>
											{(errors?.NIP && (errors?.NIP?.message || "Error!")) || t('usrnip')}
										</small>
									</Form.Text>
								</FloatingLabel>
							</Col>
							{/* Email */}
							<Col sm={12} md={6}>
								<FloatingLabel controlId="frmReg.labelemail" label={t('form.login.email.text')+":"}>
									<Form.Control
										className={(errors?.mail && "is-invalid") || "is-valid"}
										type='email'
										{...register("mail", {
											required: {
												value: true,
												message: t('form.login.errors.req'),
											},
											minLength: {
												value: 3,
												message: t('form.login.errors.min', {min_nr: 3}),
											},
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: t('form.login.errors.email'),//"invalid email address"
											},
										})}
										//defaultValue={form.mail}
									/>
									<Form.Text className={errors?.mail ? "invalid-feedback" : "text-muted"}>
										<small>
											{(errors?.mail && (errors?.mail?.message || "Error!")) || t('form.login.email.text-label')}
										</small>
									</Form.Text>
								</FloatingLabel>
							</Col>
						</Row>
						<Row className="mb-3">
							{/* Password */}
							<Col sm={12} md={6} className='mb-3'>
								<FloatingLabel controlId="frmReg.labelpassword" label={t('form.login.password.text')+":"}>
									<Form.Control
										className={(errors?.password && "is-invalid") || "is-valid"}
										type='password' 
										autoComplete='on'
										{...register("password", {
											required: {
												value: true,
												message: t('form.login.errors.req'),
											},
											minLength: {
												value: 1,
												message: t('form.login.errors.min', {min_nr: 1}),
											},
										})}
									/>
									<Form.Text className={errors?.password ? "invalid-feedback" : "text-muted"}>
										<small>
											{(errors?.password && (errors?.password?.message || "Error!")) || t('form.login.password.text-label')}
										</small>
									</Form.Text>
								</FloatingLabel>
							</Col>
							{/* RE-Password */}
							<Col sm={12} md={6}>
								<FloatingLabel controlId="frmReg.labelrepassword" label={t('form.login.re_password.text')+":"}>
									<Form.Control
										className={(errors?.re_password && "is-invalid") || "is-valid"}
										type='password' 
										autoComplete='on'
										{...register("re_password", {
											required: {
												value: true,
												message: t('form.login.errors.req'),
											},
											minLength: {
												value: 1,
												message: t('form.login.errors.min', {min_nr: 1}),
											},
											validate: (value) => {
												// Сравниваем значение поля "re_password" с полем "password"
												return value === getValues("password") || t('form.login.errors.password_mismatch');
											},
										})}
									/>
									<Form.Text className={errors?.re_password ? "invalid-feedback" : "text-muted"}>
										<small>
											{(errors?.re_password && (errors?.re_password?.message || "Error!")) || t('form.login.re_password.text-label')}
										</small>
									</Form.Text>
								</FloatingLabel>
							</Col>
						</Row>
						
						<Row className='mb-3'>
							<Col sm={12}>
								<Card>
									<Card.Header>
										{t('usraddr')}
									</Card.Header>
									<Card.Body>
										<Row className='mb-3'>

											{/* kodp */}
											<Col sm={12} md={4} lg={3}>
												<FloatingLabel controlId="frmReg.labelkod" label={t('zam.frmeditaddr.kodp')+":"}>
													<Form.Control
														className={(errors?.KodP && "is-invalid") || "is-valid"}
														type='input'
														{...register("KodP", {
															required: {
																value: true,
																message: t('form.login.errors.req'),
															},
															minLength: {
																value: 3,
																message: t('form.login.errors.min', {min_nr: 3}),
															},
														})}
														//defaultValue={form.Ulic}
													/>
													<Form.Text className={errors?.KodP ? "invalid-feedback" : "text-muted"}>
														<small>
															{(errors?.KodP && (errors?.KodP?.message || "Error!")) || t('zam.frmeditaddr.kodp')}
														</small>
													</Form.Text>
												</FloatingLabel>
											</Col>

											{/* city */}
											<Col sm={12} md={8} lg={9}>
												<FloatingLabel controlId="frmReg.labecity" label={t('zam.frmeditaddr.city')+":"}>
													<Form.Control
														className={(errors?.Mias && "is-invalid") || "is-valid"}
														type='input'
														{...register("Mias", {
															required: {
																value: true,
																message: t('form.login.errors.req'),
															},
															minLength: {
																value: 3,
																message: t('form.login.errors.min', {min_nr: 3}),
															},
														})}
														//defaultValue={form.Ulic}
													/>
													<Form.Text className={errors?.Mias ? "invalid-feedback" : "text-muted"}>
														<small>
															{(errors?.Mias && (errors?.Mias?.message || "Error!")) || t('zam.frmeditaddr.city')}
														</small>
													</Form.Text>
												</FloatingLabel>
											</Col>
										</Row>
										<Row className="mb-3">
											{/* Ulica */}
											<Col sm={12}>
												<FloatingLabel controlId="frmReg.labeladr" label={t('zam.frmeditaddr.street')+":"}>
													<Form.Control
														className={(errors?.Ulic && "is-invalid") || "is-valid"}
														type='input'
														{...register("Ulic", {
															required: {
																value: true,
																message: t('form.login.errors.req'),
															},
															minLength: {
																value: 3,
																message: t('form.login.errors.min', {min_nr: 3}),
															},
														})}
														//defaultValue={form.Ulic}
													/>
													<Form.Text className={errors?.Ulic ? "invalid-feedback" : "text-muted"}>
														<small>
															{(errors?.Ulic && (errors?.Ulic?.message || "Error!")) || t('zam.frmeditaddr.street')}
														</small>
													</Form.Text>
												</FloatingLabel>
											</Col>
										</Row>
										<Row className="mb-3">
											{/* Telefon */}
											<Col sm={12} /*md={6}*/>
												<FloatingLabel controlId="frmReg.labeltel" label={t('zam.frmeditaddr.tel')+":"}>
													<Form.Control
														className={(errors?.Tk && "is-invalid") || "is-valid"}
														type='input'
														{...register("Tk", {
															required: {
																value: true,
																message: t('form.login.errors.req'),
															},
															minLength: {
																value: 3,
																message: t('form.login.errors.min', {min_nr: 3}),
															},
														})}
														//defaultValue={form.Tk}
													/>
													<Form.Text className={errors?.Tk ? "invalid-feedback" : "text-muted"}>
														<small>
															{(errors?.Tk && (errors?.Tk?.message || "Error!")) || t('zam.frmeditaddr.tel')}
														</small>
													</Form.Text>
												</FloatingLabel>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						</>
					:
						reg_data &&
						<>
							<Alert 
								variant='primary' 
								className='shadow rounded-3'
							>
								{parse(reg_data || '')}
							</Alert>
						</>
					}
					<Row className={`round ${(errors_reg?.message) ? "" : " d-none"}`} >
						<Col>
							<Alert variant="danger" className='shadow round'>
								{parse(errors_reg?.message || '') || "Nothing to display"}
							</Alert>
						</Col>
					</Row>

					</Modal.Body>

					<Modal.Footer>
						<Row>
							<Col>
								{/* {FrmShow  && 
									<ReCAPTCHA sitekey={apiKeyK} onChange={handleReCaptchaChange} />
								} */}
							</Col>
						</Row>
						<Row className="mb-2 w-100">
							<Col className='text-start justify-content-start p-0'>
								<Button variant="secondary" onClick={RegisterHide} className='d-flex align-items-center ani-shad'>
									<XCircleFill />&nbsp;{t("form.buttons.close")}
								</Button>
							</Col>
							<Col className='text-end justify-content-end p-0 d-flex align-items-center'>
								{(!reg_data && isNOEmptyArr(firmConfig?.Nazw3) && firmConfig?.Nazw3[3] === 'T') ?
									<>
										<Button variant="primary" type='submit' className='d-flex align-items-center ani-shad ' disabled={!isValid}>
											<PersonPlusFill />&nbsp;{t('form.buttons.register')}
										</Button>
									</>
								:
									<>
										{/* <Button variant="success" className='d-flex align-items-center ani-shad '>
											<PersonPlusFill />&nbsp;{t('form.buttons.registers')}
										</Button> */}
									</>
								}
							</Col>
						</Row>
					</Modal.Footer>
				</Form>
			</Modal>
		}
		</>
	)


}