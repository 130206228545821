import React from 'react';
import { Alert, Card, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import parse from 'html-react-parser';
import { Building } from 'react-bootstrap-icons';
/* for translate */
import { useTranslation } from 'react-i18next';
//import { Form } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { cntr_horiz } from '../App';
import { AddressMap } from './Layouts/AddressMap';
import { d_release, v_release } from '../js/global';
import useAuthContext from '../Context/AuthContext';
import { isNOEmptyArr } from '../js/func';


export const Contacts = () => {
	const { firmInfo } = useAuthContext();
	const {t} = useTranslation();
	let placement = "bottom";
	return(
		<>
			<div>
				<Container fluid>
					<Card className='shadow'>
						<Card.Header className={`${cntr_horiz} card-success`}  style={{ display: 'flex', alignItems: 'center' }}>
							<OverlayTrigger
								//key=top
								placement={placement}
								overlay={
									<Tooltip id={`tooltip-${placement}`}>
										{t("navbar.contacts")}
									</Tooltip>
								}
							>
								<span style={{ display: 'flex', alignItems: 'center' }}>
									{/* <Building color="royalblue" size={96} /> &nbsp;  */}
									<Building  /> &nbsp;
									<strong>{t("navbar.contacts")}</strong>
								</span>
							</OverlayTrigger>
						</Card.Header>
						<Card.Body >
							{/* <Card.Text> */}
							{firmInfo?.Nazw ?
								<FirmContact info={firmInfo} />
							:
								<ContactSTD />
							}
							{/* <Card.Text> */}

							<hr />
							<Alert variant='info' className='text-muted p-1 text-end shadow rounded-3'>
								<small>Release: {v_release} (<i>{d_release}</i>)</small>
							</Alert>
						</Card.Body>
					</Card>
				</Container>
			</div>
		</>
	)
}





const ContactSTD = () => {
	return (
		<div>
			{/* <Card.Text> */}
			<Row>
				<Col xs={8} sm={8} md={8}>
					<b data-toggle="tooltip" data-placement="right" title = "Program do serwisu" className="red">Infolinia</b><br />
					<a href="tel:+48426507030">tel. (42) 650 70 30</a><br />
					<a href="tel:+48422920704">fax. (42) 292 07 04</a><br />
					<br />
			
					<b data-toggle="tooltip" data-placement="right" title="Program do faktur" className="red">Pomoc techniczna</b><br />
					W godz. 8.30 - 15.30<br />
					<a href="mailto:admin@firmatec.pl">e-mail: admin@firmatec.pl</a><br />
					<a href="mailto:serwis@firmatec.pl">e-mail: serwis@firmatec.pl</a><br />
					<a href="mailto:se@firmatec.pl">e-mail: es@firmatec.pl</a><br />
					<br />
					
					<strong data-toggle="tooltip" data-placement="right" title="Jak kupić Program do faktur" className="red">Dział sprzedaży</strong><br />
					e-mail:<br /> 
					<a href="mailto:info@firmatec.pl">info@firmatec.pl</a><br />
					<br /> 
					
					<strong className="red">
						FirmaTec Sp.J.
					</strong>
					
					<br />

					91-341 Łódź<br />
					ul. Brukowa 131<br />
					<strong>NIP: </strong>947-195-66-88<br />
					<strong>REGON: </strong>100564120<br />
					<br />
					
					<strong className="red"  data-toggle="tooltip" data-placement="right" title="Jak zapłacić za program do fakturowania">Konta bankowe</strong><br />
					<b>PKO BP SA oddział 6 w Łodzi</b><br />
					65 1020 3408 0000 4502 0211 4148<br />
					<br />

					<b>mBank</b><br />
					82 1140 2004 0000 3702 5769 7086<br /><br />
					
					<strong className="red">KRS:</strong> 0000312688<br />
					<strong>Sąd Rejonowy:</strong> Sąd Rejonowy dla Łodzi-Śródmieście w Łodzi, XX Wydział Krajowego Rejestru Sądowego<br />
					<br />
				</Col>
				<Col xs={4} sm={4} md={4} className='text-right'>
					<Image 
						className="float-end kontakt rounded-3"
						fluid="true"
						rounded='0'
						//roundedCircle='true'
						src="http://firmatec.pl/img/kontakt_gfx2.jpg"
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					{/* GOOGLE MAP */}
					<Card className='shadow'> 
						<AddressMap />
					</Card>
				</Col>
			</Row>
			{/* </Card.Text> */}
		</div>
	)
};


const FirmContact = ({info}) => {
	const { t } = useTranslation();
	return (
		<>
			<Row>
				<Col xs={8} sm={8} md={8}>
					<h1 className='text-center text-muted'>
						<b>
							{info?.Nazw2 || info?.Nazw }
						</b>
					</h1>

					<b className="conts">{t('offaddr')}:</b><br />
					{`${info?.ulic}`}
					<br />

					{info?.mias &&
						<>
							{`${info?.kodp ? info?.kodp + `, ` : ``}${info?.mias}`}<br />
						</>
					}

					<br />

					{info?.WWW &&
						<>
							<b className="conts">{t('www')}:</b><br />
							<a href={`${info?.WWW}`} target='blank'>{info?.WWW}</a>
							<br /><br />
						</>
					}

					{info?.mail &&
					<>
						<b className="conts">{t('form.login.email.text')}:</b><br />
						<a href={`mailto:${info?.mail}`}>{info?.mail}</a>
						<br /><br />
					</>
					}

					{(info?.t1 || info?.t2 || info?.fax) &&
						<>
							<b className="conts">{t('zam.frmeditaddr.tel')}:</b><br />
							{info?.t1 && 
								<><a href={`tel:${info?.t1}`}>tel. {info?.t1}</a><br /></>
							}
							{info?.t2 && 
								<><a href={`tel:${info?.t2}`}>tel. {info?.t2}</a><br /></>
							}
							{info?.fax &&
								<><a href={`tel:${info?.fax}`}>fax. {info?.fax}</a><br /></>
							}
							<br />
						</>
					}
					
					{(info?.nip || info?.reg) &&
						<>
							{info?.nip &&
								<><strong>NIP: </strong>{info?.nip}<br /></>
							}
							{info?.reg &&
								<><strong>REGON: </strong>{info?.reg}<br /></>
							}
						<br />
						</>
					}


					{isNOEmptyArr(info?.Banks) &&
						<>
							<strong className="conts">{t('bank')}:</strong><br />
							{info?.Banks.map((el, i) => (
								<div key={'bank'+i}>
									<b>{el?.Bank}</b><br />	
									{el?.Nr}<br />
								</div>
							))}
							<br />
						</>
					}

					{info?.AddInfo &&
						<div>
							{t('addinfo')}:
							{parse(info?.AddInfo)}
						</div>
					}

					<br />
				</Col>
				<Col xs={4} sm={4} md={4} className='text-right'>
					<Image 
						className="float-end kontakt rounded-3"
						fluid="true"
						//roundedCircle='true'
						src="http://firmatec.pl/img/kontakt_gfx2.jpg"
					/>
				</Col>
			</Row>
		</>
	)
}