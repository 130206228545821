import { createContext, useContext, useEffect, useState } from "react";
//import axios from "axios";
import axios from "../api/axios";
import { SpinnerWait } from "../Pages/Layouts/SpinnerWait";
import { isReq_data_Obj, parse_to_JS } from "../js/func";
import { FirmCONFIG_Local_Storage, FirmINFO_Local_Storage, USERDATA_Local_Storage, UrlRedirAfterLogin, isGlobalLogoff, v_release } from "../js/global";
import cookies from 'js-cookie';
import i18next from 'i18next';
import { langs } from "../Components/Locale/Locales";
import { setLocale } from "../Components/Locale/Locale_chooser";

//https://www.youtube.com/watch?v=6vskJRuPlfk

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

	const User_Local_Storage = USERDATA_Local_Storage;

	const auth_url = '/auth';
	useEffect(()=>{
		axios.defaults.headers.common['User-Lng'] = (cookies.get('i18next') || 'pl');
		axios.defaults.headers.common['Accept-Language'] = `${localStorage.getItem("i18nextLng") || (cookies.get('i18next') || 'pl')},en`;
		//'Accept-Language': `${localStorage.getItem("i18nextLng") || 'pl'},en`,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem("i18nextLng"), cookies.get('i18next')]);

	const [firmConfig, setFirmConfig] = useState({});
	const [firmInfo, setFirmInfo] = useState({});
	const [user, setUser] = useState(null);
	const [user_data, setUser_data] = useState(null);
	const [errors_login, setErrors_login] = useState([]);
	const [errors_user, setErrors_user] = useState([]);
	const [errors_reg, setErrors_reg] = useState('');
	const [reg_data, setReg_data] = useState(null);

	// for spinner
	const[spin, setSpin] = useState(true);
	const showSpin = () => {
		setSpin(true);
	}
	const hideSpin = () => {
		setSpin(false);
	}

	const SetTMPUser = async (userdata) => {
		setUser(userdata)
	}

	const getUser_data = async() => {
		showSpin();
		let obj = parse_to_JS(localStorage.getItem(User_Local_Storage));
		GetFirmInfo();
		if (!obj){
			await getUser();
			obj = parse_to_JS(localStorage.getItem(User_Local_Storage))
			setFirmConfig(parse_to_JS(sessionStorage.getItem(FirmCONFIG_Local_Storage)) || {})
		}
		if (obj){
			setUser_data({
				...obj, 
			});
			if (parseFloat(obj?.v || 0) < parseFloat(isGlobalLogoff)){
				Reset_User()
				hideSpin();
				return null;
			}
			hideSpin();
			return true;
		}
		//console.log('GETUSERDATA');
		Reset_User();
		hideSpin();
		return null;
	}

	/**
	 * Get current user or error
	 */
	const getUser = async () => {
		setErrors_user(null);
		showSpin();
		await axios
			.get(auth_url, {
				params: {
					/*hash: 'qwerty123445', 
					isval: 'second value',
					dbal: "test",
					usr: "info@firmatec.pl",*/
				}
			})
			.then(response => {
				response.data.data.v = v_release;
				localStorage.setItem(User_Local_Storage, JSON.stringify(response.data.data));
				setUser(response.data.data);
			})
			.catch((error) => {
				if (error?.response) {
					if (!(error.response.data?.error?.error === 'not_logged' && errors_login))
						setErrors_login(error.response.data?.error);
				} 
				else {
					setErrors_login("An error occurred. Please try again later.");
				}
				//console.log('GETUSER');
				Reset_User();
			})
			.finally(response => {
				hideSpin()
			});
	};


	const IsLoggedUser = () => {
		return (!!user_data?.User?.ID || !!user_data?.User?.Nazw || !!user_data?.User?.Alias);
	}


	/**
	 * Get Firm configs  
	 * 
	 */
	const GetFirmCfg = async (dbal) => {
		setErrors_login(null);
		showSpin();
		await axios
			.get(
				auth_url + '/setfirm/' + dbal ,
				JSON.stringify ({
					dbal: `${dbal}`,
					zam_online: true
				}), 
				{
					headers: {
						"Content-Type" : "application/json",
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Allow-Credentials': true,
						'Zam-Online': true
					},
					withCredentials: true
				}, 
			)
			.then(response => {
				return response?.data
			})
			.then (data => {
				setFirm_Config(data);
			})
			.catch((error) => {
				if (error?.response) {
					setErrors_login(error.response.data?.error);
				} 
				else {
					setErrors_login("An error occurred. Please try again later.");
				}
				//console.log('GETFRIMCFG');
				Reset_User(true);
				reSetFirm_Config();
			})
			.finally(response => {
				hideSpin()
			});
	};
	

	/**
	 * Login 
	 * 
	 */
	const login = async ({ ...data }) => {
		setErrors_login(null);
		showSpin();
		await axios
			.post(
				auth_url,
				JSON.stringify ({
					dbal: `${data?.dbal}`,
					usr: `${data?.email}`,
					pass: `${data?.pass}`,
					//credents: `${basic_auth}`,
					remember: `${data.rem_sw}`,
					zam_online: true
				}), 
				{
					headers: {
						"Content-Type" : "application/json",
						//"Accept-Language": `${localStorage.getItem("i18nextLng") || 'pl'}`,
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Allow-Credentials':true,
						'Zam-Online': true
					},
					withCredentials: true
				}, 
			)
			.then(response => {
				return response?.data
			})
			.then (data => {
				let dataforset = data?.UserInfo;
				dataforset.v = v_release;
				localStorage.setItem(User_Local_Storage, JSON.stringify(dataforset));
				setUser(data?.UserInfo);
				let to_route = sessionStorage.getItem(UrlRedirAfterLogin);
				sessionStorage.setItem(UrlRedirAfterLogin, 'no');
				sessionStorage.removeItem(UrlRedirAfterLogin);
				//localStorage.setItem(FirmCONFIG_Local_Storage, JSON.stringify(parse_to_JS(sessionStorage.getItem(FirmCONFIG_Local_Storage))?.Kontakt));
				//localStorage.setItem(FirmINFO_Local_Storage, JSON.stringify(firmInfo));
				//localStorage.setItem(FirmINFO_Local_Storage, sessionStorage.getItem(FirmINFO_Local_Storage));
				sessionStorage.removeItem(FirmCONFIG_Local_Storage);
				sessionStorage.removeItem(FirmINFO_Local_Storage);
				//sessionStorage.clear();
				if (!to_route || to_route === 'no'){
					to_route = '/';
				}

				// Set locale for user-settings
				if (data?.UserInfo?.User?.jez){
					setLocale(data?.UserInfo?.User?.jez || 'pl');
				}
				window.location.href = to_route;
			})
			.catch((error) => {
				if (error.response) {
					setErrors_login(error.response.data?.error);
				} 
				else {
					setErrors_login("An error occurred. Please try again later.");
				}
				//console.log('LOGIN');
				Reset_User();
				//setErrors_login(response?.data?.error);
				//Reset_User();
			})
			.finally(response => {
				hideSpin()
			});
	};

	
	/**
	 * Register 
	 * 
	 */
	const User_Register = async (data) => {
		setErrors_reg(null);
		setReg_data(null);
		showSpin();
		await axios
			.put(
				auth_url + '/' + data?.dbal,
				JSON.stringify ({
					...data,
					zam_online: true
				}), 
				{
					headers: {
						"Content-Type" : "application/json",
						//"Accept-Language": `${localStorage.getItem("i18nextLng") || 'pl'}`,
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Allow-Credentials':true,
						'Zam-Online': true
					},
					withCredentials: true
				}, 
			)
			.then(response => {
				return response?.data
			})
			.then (data => {
				/*localStorage.setItem(User_Local_Storage, JSON.stringify(data?.UserInfo));
				setUser(data?.UserInfo);
				let to_route = sessionStorage.getItem(UrlRedirAfterLogin);
				sessionStorage.setItem(UrlRedirAfterLogin, 'no');
				sessionStorage.removeItem(UrlRedirAfterLogin);
				*/
				setReg_data(data);
				//localStorage.setItem(FirmCONFIG_Local_Storage, JSON.stringify(parse_to_JS(sessionStorage.getItem(FirmCONFIG_Local_Storage))?.Kontakt));
				//localStorage.setItem(FirmINFO_Local_Storage, JSON.stringify(firmInfo));
				//localStorage.setItem(FirmINFO_Local_Storage, sessionStorage.getItem(FirmINFO_Local_Storage));
				/*sessionStorage.removeItem(FirmCONFIG_Local_Storage);
				sessionStorage.removeItem(FirmINFO_Local_Storage);*/
				//sessionStorage.clear();
				/*if (!to_route || to_route === 'no'){
					to_route = '/';
				}
				window.location.href = to_route;*/
			})
			.catch((error) => {
				if (error.response) {
					setErrors_reg(error.response.data?.error);
				} 
				else {
					setErrors_reg("An error occurred. Please try again later.");
				}
				//Reset_User();
				//setErrors_login(response?.data?.error);
				//Reset_User();
			})
			.finally(response => {
				hideSpin()
			});
	};
	

	
	/**
	 * LogOUT
	 */
	const logout = async () => {
		const res_usr = async () => {
			//console.log('LOGOUT');
			await Reset_User(true);
		}
		res_usr();
		setErrors_user(null);
		showSpin();
		await axios
			.get(auth_url + '/logout',{
				params: {
					"logout": true
				}
			})
			.then(response => {
				if (response.status === 401){
					window.location.href = '/';
				}
					//window.location.reload(false);
				//console.log(response)
				window.location.href = '/';
				//window.location.reload(false);
			})
			.catch(({response}) => {
				if (response.status === 401)
					window.location.href = '/';
					//window.location.reload(false);
				setErrors_user(response.data.error);
			})
			.finally(response => {
				hideSpin()
			});
	};

	/**
	 * Reset user-data
	 */
	const Reset_User = async (all=false) =>{
		setUser(null);
		setUser_data(null);
		let tmpinfo = sessionStorage.getItem(FirmINFO_Local_Storage);

		//console.log('RESET USER', firmInfo);
		localStorage.clear();
		/*if (!isReq_data_Obj(firmInfo)){
			sessionStorage.clear();
		}*/

		if (!!tmpinfo && !all){
			localStorage.setItem(FirmINFO_Local_Storage, tmpinfo);
			setFirmInfo(parse_to_JS(tmpinfo))
		}

		//localStorage.removeItem(User_Local_Storage);
	}

	/**
	 * Set config of Firm to sessionStorage
	 * @param {object} config 
	 */
	const setFirm_Config = (config) => {
		sessionStorage.setItem(FirmCONFIG_Local_Storage, JSON.stringify(config));
		setFirmConfig(config);
		sessionStorage.setItem(FirmINFO_Local_Storage, JSON.stringify(config?.Kontakt))
		localStorage.setItem(FirmINFO_Local_Storage, JSON.stringify(config?.Kontakt))
		setFirmInfo(config?.Kontakt);
	}

	/**
	 * Reset Firm config
	 */
	const reSetFirm_Config = () => {
		sessionStorage.removeItem(FirmCONFIG_Local_Storage);
		setFirmConfig({});
	}

	/**
	 * Get firm info from local storage
	 */
	const GetFirmInfo = () => {
		// Set firm info
		let kont = parse_to_JS(localStorage.getItem(FirmINFO_Local_Storage) || "");
		//console.log('kont', kont,isReq_data_Obj(kont) );
		if (isReq_data_Obj(kont))
			setFirmInfo(kont);
	}

	return (
		<SpinnerWait show={spin}>
			<AuthContext.Provider 
				value={{ 
					firmInfo, 
					GetFirmInfo, 
					firmConfig, 
					setFirm_Config, 
					GetFirmCfg, 
					user_data, 
					user, 
					IsLoggedUser,
					errors_login, 
					setErrors_login, 
					errors_reg, 
					reg_data, 
					setReg_data, 
					setErrors_reg, 
					SetTMPUser, 
					getUser_data, 
					getUser, 
					login, 
					logout, 
					User_Register, 
					errors_user, 
					spin, 
					showSpin, 
					hideSpin 
				}}
			>
				{children}
			</AuthContext.Provider>
		</SpinnerWait>
	);

}


export default function useAuthContext () {
	return useContext(AuthContext);
}