import { createContext, useContext, useState, useEffect } from "react";
import { Toast } from "react-bootstrap";
import { BoxSeam } from "react-bootstrap-icons";
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";

const ToastContext = createContext({});

export const ToastProvider = ({ children }) => {
	const [toastlist, setToastlist] = useState([]);


	const {t} = useTranslation();

  const ADDToast = (msg='Test', bg='primary', show=true) => {
		//console.log("ADDToast")
		setToastlist(
			toastlist.concat(
				<ShowToast 
					show={show} 
					bg={bg} 
					msg={parse(msg) || "No message"} 
					key={toastlist?.length || 0} 
					ind={toastlist?.length || 0} 
				/>
			)
		);
  };


	const ShowToast = (props) => {
		const [show, setShow] = useState(props.show);
		return (
		<>
			<Toast 
				onClose={() => setShow(false)} 
				show={show} 
				delay={3000} 
				autohide 
				aria-live="assertive" 
				aria-atomic="true"
				bg={props.bg || "success"}
			>
				<Toast.Header>
					<BoxSeam />&nbsp; 
					<strong className="me-auto text-capitalize">{t('app_title')}</strong>
				</Toast.Header>
				<Toast.Body>
					<strong className="text-white">{props.msg}</strong>
				</Toast.Body>
			</Toast>
		</>
		)
	};


	return (
		<ToastContext.Provider value={{ ADDToast }}>
			<div className="toast-container position-fixed top-0 end-0 p-3">
				{toastlist}
			</div>
			{children}
		</ToastContext.Provider>);
}



export default function useToastContext () {
	return useContext(ToastContext);
}