import { Navigate } from "react-router-dom";
import useAuthContext from "../Context/AuthContext";
import { UrlRedirAfterLogin } from "../js/global";
import { IsGuestAllow } from "../js/firm_def_configs";
 
const ProtectedRoute = ({ children, accessBy }) => {
	const { user_data, spin, IsLoggedUser } = useAuthContext();
	
	//if spinner is shows
	if (spin) return null;

	let Logged = IsLoggedUser();

	// Recource for non-authenticated only
	if ((accessBy === "non-authenticated") && (!Logged )) {
		return children;
	}
	// Recource for authenticated only
	else if ((accessBy === "authenticated") && (Logged)) {
		return children;
	}
	// Recource for admin only
	else if ((accessBy === "admin") && (user_data?.User?.UZYT)) {
		return children;
	}	
	else if (accessBy === "towary" && (Logged || (!Logged && IsGuestAllow())))
		return children;
	
	/* If user go into some route */
	if ((sessionStorage.getItem(UrlRedirAfterLogin) || 'no') === 'no')
		sessionStorage.removeItem(UrlRedirAfterLogin)
	else
		sessionStorage.setItem(UrlRedirAfterLogin, window.location.pathname)

	return <Navigate to="/start"></Navigate>;
};


export default ProtectedRoute;
