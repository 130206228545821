import { useEffect } from 'react';
import Favicon from 'favico.js';

const BadgeFavicon = ({ badgeText }) => {
  useEffect(() => {
    const favicon = new Favicon({
      animation: 'popFade',
      bgColor: '#f00', 		// Цвет фона бейджа
      textColor: '#fff', // Цвет текста бейджа
    });

    favicon.badge(badgeText);
    
    return () => {
      favicon.reset(); // Сбросить бейдж при размонтировании компонента
    };
  }, [badgeText]);

  return null; // Компонент не рендерит ничего на странице
};

export default BadgeFavicon;
