import React, { createContext, useContext, useEffect, useState } from 'react'
import { isEqual } from 'lodash';
import BadgeFavicon from '../Pages/Layouts/BadgeFavico';
//import useAuthContext from './AuthContext';
//import { B_to_N, N_to_B } from '../js/func';
import { USERCART_Local_Storage, USERCONFIG_Local_Storage, custID, v_release } from '../js/global';

const CartContext = createContext({});

export const CartProvider = ({ children }) => {
	const storedCart = localStorage.getItem(USERCART_Local_Storage);
	const [storedConf, setStoredConf] = useState(JSON.parse(localStorage.getItem(USERCONFIG_Local_Storage)));
	const [oneWAL, setOneWAL] = useState(false)
	const initialCart = storedCart ? JSON.parse(storedCart) : [];
	const [userCart, setUserCart] = useState(initialCart);
	const [cartSum, setCartSum] = useState({Nsum: 0, Bsum: 0});
	//const { user_data } = useAuthContext();
	//const [cartUpdatedHandled, setCartUpdatedHandled] = useState(false);

	/*// В других вкладках
	function handleCartUpdate() {
		console.log('Reinit Cart');
		// Проверяем, было ли событие уже обработано
		if (!cartUpdatedHandled) {
			setCartUpdatedHandled(true);
			// Загрузка данных из localStorage
			setUserCart(JSON.parse(localStorage.getItem(USERCART_Local_Storage)));
		}
	}

	// Слушаем событие обновления корзины
	useEffect(() => {
		window.addEventListener('cartUpdated', handleCartUpdate);
		return () => {
			window.removeEventListener('cartUpdated', handleCartUpdate);
		};
	}, []);*/


	/* If userCart was changed - set localstorage to new array of user-cart */
	useEffect(() => {
		const one_wal = checkAllHaveSameField(userCart, 'wal');
		setOneWAL(one_wal);
		let Nsum = 0;
		let Bsum = 0;
		if (one_wal){
			userCart.forEach(el => {
				Nsum += parseFloat(el.CN)*parseFloat(el.qty);
				Bsum += parseFloat(el.CB)*parseFloat(el.qty);
			});
		};
		
		setCartSum({
			Bsum: Bsum.toFixed(2),
			Nsum: Nsum.toFixed(2)
		});
		localStorage.setItem(USERCART_Local_Storage, JSON.stringify(userCart));

		// Генерирование события о обновлении корзины только если событие не обработано
		/*if (!cartUpdatedHandled) {
			const event = new Event('cartUpdated');
			window.dispatchEvent(event);
		}
		//else 
		//	setCartUpdatedHandled(false)*/
		
	}, [userCart]);
	
	
	/**
	 * Add item (or qty) to cart 
	 * @param {object} item 
	 */
	const AddToCart = (item) => {
		let itmInCart = false;

		// Add some new item and not input name
		if (item.ID === custID && !item?.Nazw)
			// If no Nazw - get not from 0 to 200
			item.Nazw = item?.not.slice(0,200)
	
		// Maybe add some qty to current item
		const updatedCart = userCart.map((el) => {
			if ((el.ID === item.ID && isEqual(el.cechy, item.cechy)) && ((item.ID !== custID) || (item.ID === custID && item.Nazw === el.Nazw))) {
				el.qty = (parseFloat(el.qty) + parseFloat(item.qty)).toFixed(2);
				itmInCart = true;
			}
			return el;
		}).filter((el) => el.qty > 0); // Filter out elements with qty <= 0
	
		// If add new position
		if (!itmInCart) {
			updatedCart.push(item);
			updatedCart.sort((a, b) => a.Nazw.localeCompare(b.Nazw));
		}
		setUserCart(updatedCart);
	};

	/**
	 * Change Item-object in cart 
	 * @param {object} item 
	 */
	const ChgItmInCart = (item, fld='qty') => {
		// If exists - change field
		const updatedCart = userCart.map((el) => {
			if ((el.ID === item.ID && isEqual(el.cechy, item.cechy)) && ((item.ID !== custID) || (item.ID === custID && item.Nazw === el.Nazw))) {
				el[fld] = item[fld];
			}
			return el;
		}).filter((el) => el.qty > 0); // Filter out elements with qty <= 0
		setUserCart(updatedCart);
	};


	/**
	 * Delete item from cart
	 * @param {object} item 
	 */
	const DeleteFromCart = (item) => {
		const updatedCart = userCart.filter((el) => {
			return (item.ID + JSON.stringify(item.cechy)) !== (el.ID + JSON.stringify(el.cechy));
		});
		setUserCart(updatedCart);
	}
	
	/**
	 * Clear cart
	 * @param {object} item 
	 */
	const ClearCart = () => {
		setUserCart([]);
		localStorage.removeItem(USERCART_Local_Storage);
	}


	/**
	 * Set user-config (prices, waluta, producers, categories etc.) to local-storage
	 * @param {object} config 
	 */
	const SetUserConfig = (config) => {
		config.v = v_release;
		localStorage.setItem(USERCONFIG_Local_Storage, JSON.stringify(config));
		setStoredConf(config);
	}

	/**
	 * Set user-config (prices, waluta, producers, categories etc.) to local-storage
	 * @param {object} config 
	 */
	const GetUserConfig = () => {
		const conf = JSON.parse(localStorage.getItem(USERCONFIG_Local_Storage))
		setStoredConf(conf);
		//return JSON.parse(localStorage.getItem(USERCONFIG_Local_Storage));
	}


	const checkAllHaveSameField = (dataArr, field) => {
		if (dataArr.length === 0) {
			return false;
		}
	
		const firstItemFieldValue = dataArr[0][field];
		return dataArr.every(item => item[field] === firstItemFieldValue);
	};


	return (
	<CartContext.Provider value={{ 
		userCart, 
		cartSum, 
		AddToCart, 
		ChgItmInCart, 
		DeleteFromCart, 
		ClearCart, 
		SetUserConfig, 
		GetUserConfig, 
		storedConf, 
		oneWAL 
	}}>
		{!!(userCart.length) && 
			<BadgeFavicon badgeText={userCart.length} />
		}
		{children}
	</CartContext.Provider>
	)
}




export default function useCardContext () {
	return useContext(CartContext);
}
