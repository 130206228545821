import { useEffect, useState } from "react";
import '../../css/Items.css';
import { Alert, Button, Card, Col, Collapse, FormControl, Image, InputGroup, ListGroup, Modal, Row, Stack } from "react-bootstrap";
import { DashSquareFill, PlusSquare, CartPlus, XCircle, PersonCheckFill } from "react-bootstrap-icons";

import useToastContext from "../../Context/ToastContext";
import useCardContext from "../../Context/CartContext";
import { Form } from "react-bootstrap";

/* For translate */
import { useTranslation } from 'react-i18next';
import { custID } from "../../js/global";
import { B_to_N, N_to_B, isNOEmptyArr, isNoEmptyObj, IsPLlng } from "../../js/func";
import useAuthContext from "../../Context/AuthContext";
import { FirmName } from "../../js/firm_def_configs";
import { useNavigate } from "react-router-dom";



export const ItmDtl = ({item, config, showDtl, updateShowDtl, addToOrder}) => {
	const pllng = IsPLlng();
	const {t} = useTranslation();
	const navigate = useNavigate();
	const { ADDToast } = useToastContext();
	const { AddToCart } = useCardContext();
	const { IsLoggedUser } = useAuthContext();

	// Defenition of item params (FOR ORDER)
	const [itm, setItm] = useState({
		ID: item?.data?.ID,
		Nazw: item?.data?.Nazw,
		Nazw2: item?.data?.Nazw2,
		cechy: [],
		not: "",
		qty: 1
	});
	const [showModal, setShowModal] = useState(showDtl);
	// const openModal = () => {
	// 	setShowModal(true);
	// };
	const closeModal = () => {
		setShowModal(false);
		updateShowDtl(false);
	};

	/** Goto login form */
	const GoToLogin = () => {
		navigate(`/start/${FirmName()}`);
	};

	/* Cechy */
	const [cechyOpen, setCechyOpen] = useState(true);
	const [selectedCechy, setSelectedCechy] = useState({});
	const updateCech = async (cech, val) => {
		setSelectedCechy(prevState => ({
			...prevState,
			[cech]: {
				name: item.data.Cech[cech].name,
				sel: {
					[val]: item.data.Cech[cech].chld[val]
				}
			}
		}));
		//console.log(selectedCechy);
	}

	// Load new ITEM - set default cechy and itm.ID
	useEffect(() => {
		// If item?.data?.Cech - not object or have no one key - return
		//return;
		const Res = async() => {
			await setSelectedCechy({});
		}
		//if (((item?.data?.Cech && typeof item?.data?.Cech === 'object') && Object.keys(item?.data?.Cech).length > 0)){
		if (isNoEmptyObj(item?.data?.Cech)){
			Res();
			// Set defaults values
			// eslint-disable-next-line no-unused-vars, array-callback-return
			const initialValues = Object.keys(item?.data?.Cech).map((cech, i) => {
				//const val = item?.data?.Cech[cech]?.chld[0];
				const val = Object.keys(item?.data?.Cech[cech]?.chld || {})[0];
				updateCech(cech, val)
			});
		}
		setItm({
			...itm,
			ID: item?.data?.ID,
			Kod: item?.data?.Kod,
			kat: item?.data?.Kat,
			Nazw: item?.data?.Nazw,
			Nazw2: item?.data?.Nazw2,
			//price: item?.data?.Cena_tow,
			CN: item?.data?.CN,
			CB: item?.data?.CB,
			jm: item?.data?.jm,
			Vat: item?.data?.Vat,
			NVat: item?.data?.NVat,
			PrRab: item?.data?.PrRab,
			NB: item?.data?.NB,
			mag: item?.data?.mag,
			wal: item?.data?.wal,
			qty:1
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item]);


	useEffect(() => {
		ChangeItmVal('cechy',selectedCechy);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCechy]);


	/* Change values in object of item for order */
	const ChangeItmVal = (fieldName, value) => {
		if (itm?.ID === custID && fieldName==='Nazw'){
			itm.Nazw2 = value
		}

		// If custom iten - calculate netto & brutto
		if (itm?.ID === custID && (fieldName === 'CN' || fieldName === 'CB')){
			let fieldName2 = 'CB';
			let value2 = 0;
			if (fieldName.slice(-1) === "N"){
				value2 = N_to_B(value);
			}
			else{
				fieldName2 = "CN";
				value2 = B_to_N(value);
			}
			setItm(prevState => ({
				...prevState,
				[fieldName]: value,
				[fieldName2]: value2,
			}));
		}
		else{
			setItm(prevState => ({
				...prevState,
				[fieldName]: value
			}));
		}
	};


	/**
	 * Add item to user cart
	 * @param {boolean} close 
	 */
	const AddItmToCard = (close=true) => {
		/*console.log(itm);
return; */
		AddToCart(itm);
		setItm((prev)=>({
			...prev,
			not: ""
		}));
		ADDToast(t('zam.cart.added', {name: (pllng ? itm.Nazw : itm.Nazw2)}), 'success')
		if (close) closeModal();
	};


	/** SHOW form */
	useEffect(() => {
		if (item?.data && !Array.isArray(item?.data))
			setShowModal(showDtl);
		else 
			closeModal();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showDtl, item?.data]);


	/* Modal window with FULL image */
	const [visIMGModal, setVisIMGModal] = useState(false)
	const setVis_IMGModal = (val) => {
		setVisIMGModal(val)
	}


	/* Increase or decrease qty of item */
	const handleIncrease = () => {
		ChangeItmVal('qty', (itm.qty + 1));
	};

	const handleDecrease = () => {
		if (itm.qty > 1)
			ChangeItmVal('qty', (itm.qty - 1));
	};
	


	return(
		<>
			<Modal 
				show={showModal && !visIMGModal} 
				onHide={closeModal} 
				centered
				size="lg"
				//dialogClassName="custom-modal"
			>
				<Modal.Header className="rounded-3 panel-heading bg-primary bg-gradient opacity-75 p-2">
					<b>
						{itm?.ID === custID ? 
							<>{t('zam.frmItem.add')}</>
						:
							<>{t('zam.frmItem.details')}: <u>{(pllng ? itm.Nazw : itm.Nazw2)}</u></>
						}
					</b>
				</Modal.Header>
				<Modal.Body>

					{itm ?
						<>
							{/* s9:='N';   // Pokazać „kod”
							s10:='N';    // Pokazać  Nr katalogowy „kat” */}
							{itm?.ID !== custID && isNOEmptyArr(config?.Nazw3) && (config?.Nazw3[9] === 'T' || config?.Nazw3[10] === 'T') &&
								<Alert variant='info'>
									<Row>
									{(config?.Nazw3[9]) === 'T' &&
										<Col>
											<strong>{t('zam.frmItem.kod')}:</strong> <b className="runded"><u>{itm?.Kod}</u></b>
										</Col>
									}
									{itm?.Kat && (config?.Nazw3[10] || null) === 'T' ?
										<Col>
											<strong>{t('zam.frmItem.nr_kat')}:</strong> <b className="runded"><u>{itm?.Kat}</u></b>
										</Col>
									:
										""
									}
									</Row>
								</Alert>
							}

							<Row>
								<Col>
									<ImageDescr 
										itm={item?.data}
										setVis={(v) => setVis_IMGModal(v)} 
										setNewValDscr={(val) => ChangeItmVal('Nazw', val)}
									/>
								</Col>
							</Row>

							{/* Cechy */}
							{isNoEmptyObj(item?.data?.Cech)?
								<>
									<hr />
									<Card className="mb-2">
										<Card.Header
											className="bg-warning bg-gradient bg-opacity-25 shadow"
											onClick={() => setCechyOpen(!cechyOpen)}
											aria-controls="coll-dtl"
											aria-expanded={cechyOpen}
											as="h6"
										>
											{/* Icon of collapse */}
											<span className="d-inline-flex align-items-center">
												{cechyOpen ? <DashSquareFill className="opacity-50" /> : <PlusSquare className="opacity-75" />}&nbsp;
													<strong><abbr title={t('zam.frmItem.cechy')}>{t('zam.frmItem.cechy')}</abbr></strong>
											</span>
										</Card.Header>

										<Collapse
											in={cechyOpen} 
											id="coll-dtl"
										>
											<Card.Body className="header-condensed p-1 bg-primary bg-gradient bg-opacity-25">
												<Row className="">
													<Col className="form-group">
														<ListGroup as="ul">
														{
														Object.keys(item?.data?.Cech).map((cech, indx) => (
															<ListGroup.Item 
																as="li" 
																key={cech} 
																//disabled={!isNOEmptyArrMoreOne(item?.data?.Cech[cech]['chld'])}
															>
																<Row className="align-middle pb-2 p-md-1">
																	<Col md={6} className="text-monospace text-sm-center text-md-start pb-sm-3 pb-md-0 align-middle">
																		<b style={{ display: 'flex', alignItems: 'center', height: '100%' }}>{pllng ? cech : item?.data?.Cech[cech]['name']}</b>
																	</Col>
																	<Col md={6}>
																		<Form.Select 
																			size="sm"
																			aria-label="Select" 
																			className="shadow"
																			sortparam="grp"
																			cech={cech}
																			//disabled={(item?.data?.Cech[cech].length < 2)}
																			disabled={Object.keys(item?.data?.Cech[cech]?.chld || {}).length < 2}
																			onChange={e => updateCech(cech, e.target.value)}
																			onLoad={e => updateCech(cech, e.target.value)}
																		>
																			{Object.keys(item?.data?.Cech[cech]?.chld || {}).map((key,ii) => (
																					(pllng ? key : item?.data?.Cech[cech]?.chld[key]) !== "" ?
																						<option key={cech+key+ii} value={key}>
																							{pllng ? key : item?.data?.Cech[cech]?.chld[key]}
																						</option>
																					:
																						""
																					
        															))}
																			{/*item?.data?.Cech[cech].map((v,i) => (
																				(v !== "" ?
																					<option value={v} key={cech+v+i}>{v}</option>
																				:
																					""
																				)
																			))*/}
																		</Form.Select>
																	</Col>
																</Row>
															</ListGroup.Item>
														))
														}
														</ListGroup>
													</Col>
												</Row>
											</Card.Body>
										</Collapse>
									</Card>
								</>
							:
								""
							}

							<hr />

							{/* Notatki */}
							<Card className="mb-2">
								<Card.Header
									className="bg-warning bg-gradient bg-opacity-25 shadow"
									aria-controls="not"
									as="h6"
								>
									<strong>{t('zam.frmItem.not')}</strong>
								</Card.Header>
								<Card.Body className="header-condensed p-1 bg-opacity-25">
									<Row className="">
										<Col className="form-group">
											<Form.Control 
												as="textarea" 
												defaultValue={''}
												placeholder={t('zam.frmItem.wishes')}
												onChange={(e) => ChangeItmVal('not', e.target.value)}
											/>
										</Col>
									</Row>
								</Card.Body>
							</Card>
							<hr />

							{/* Quantity and price */}
							<Card className="mb-2">
								<Card.Header
									className="bg-warning bg-gradient bg-opacity-25 shadow"
									aria-controls="not"
									as="h6"
								>
									<strong>{t('zam.frmItem.qty')}</strong>
								</Card.Header>
								<Card.Body className="header-condensed p-1 bg-opacity-25 m-2">
									<Row className="">
										<Col className="form-group">
											<Stack direction="horizontal" gap={3}>
												<div className="">
													<InputGroup>
														<Button variant="outline-secondary" onClick={handleDecrease}>-</Button>
														<FormControl
															type="number"
															step="0.1"
															value={itm.qty}
															onChange={(e) => {ChangeItmVal('qty', (parseFloat(e.target.value) || 0))}}
															min={0.1}
															style={{ maxWidth: '80px' }}
														/>
														<Button variant="outline-secondary" onClick={handleIncrease}>+</Button>
													</InputGroup>
												</div>
												<div>
													<b style={{ display: 'flex', alignItems: 'center', height: '100%' }}>{item?.data?.jm ? ` * ` + item?.data?.jm : ''}</b>
												</div>

												{!config?.Ceny?.noCen &&
													<div className="ms-auto d-flex align-items-center">
														{t('zam.frmItem.cena')}:&nbsp;
														<strong className=" d-flex align-items-center">
															{itm?.ID !== custID ?
																<>{((config?.Ceny?.NB === "B" ? itm?.CB : itm?.CN) * itm.qty).toFixed(2)}</>
															:
																<FormControl
																	type="number"
																	step="0.1"
																	value={(itm?.NB === "B" ? (itm?.CB || 0) : (itm?.CN || 0))}
																	onChange={(e) => {ChangeItmVal('C'+itm?.NB, (parseFloat(e.target.value) || 0))}}
																	min={0}
																	style={{ maxWidth: '80px' }}
																/>
															}&nbsp;
															<i className="text-muted">{itm?.wal}</i>
														</strong>
													</div>
												}
											</Stack>
										</Col>
									</Row>
								</Card.Body>
							</Card>

							<hr />

							{/* Buttons */}
							<Stack direction="horizontal" gap={3} className="">
								<div className="tow-btn ">
									<Button 
										variant="danger" 
										onClick={closeModal}
										className="ani-shad btn-ico"
									>
										<XCircle />&nbsp;{t('zam.frmItem.btns.close')}
									</Button>
								</div>
								<div className="tow-btn ms-auto">
									{IsLoggedUser() ? 
										itm.qty && 
										<Button 
											variant="success"
											onClick={(e) => {AddItmToCard()}}
											className="ani-shad btn-ico"
											disabled={!itm.qty || (itm.ID === custID & !(itm?.Nazw || itm.not))}
										>
											<CartPlus />&nbsp;{t('zam.frmItem.btns.add')}
										</Button>
									:
										<Button 
											variant="primary" 
											type='submit' 
											className='d-flex align-items-center ani-shad w-100'
											onClick={GoToLogin}
											//onClick={() => UsrLoginShow()} 
										>
											<PersonCheckFill />&nbsp;{t("form.buttons.login")}
										</Button>
									}
								</div>
							</Stack>
						</>
					:
						""
					}
				</Modal.Body>
			</Modal>


			{/* Modal of NOT resized img */}
			<ImgModal 
				img={item?.data?.photo?.blob?.data} 
				mime={item?.data?.photo?.blob?.mime} 
				vis={visIMGModal} 
				setVis={setVis_IMGModal} 
			/>
		</>
	)
}



/**
 * Description and image
 * @param {*} param0 
 * @returns 
 */
const ImageDescr = ({itm, setVis, setNewValDscr}) =>{

	const { t } = useTranslation();
	const pllng = IsPLlng();

	const setVisibleModalImg = () =>{
		setVis(true)
	}

	return(
		<>
			{/* Photo */}
			<Col className={`` + !!itm?.Opis ? `tow-img m-0 align-items-center h-100` : "tow-img px-1"}>
				{itm?.ID !== custID &&
					<div className={`float-none m-2 shadow rounded-4 ` + (itm?.photo?.blob?.data ? "" : "opacity-25")}>
						{itm?.photo?.blob?.data ?
							<Image 
								src={`data:${itm?.photo?.blob?.mime};base64,${itm?.photo?.blob?.data}`} 
								onClick={setVisibleModalImg}
								style={{ maxWidth: '150px'}}
								className="shadow rounded-4 img-fluid float-start p-1 me-3"
							/>
						:
							<Image 
								src={process.env.PUBLIC_URL + '/assets/images/no-item.png'} 
								className="shadow rounded-4 img-fluid float-start me-3"
								style={{ maxWidth: '150px'}} 
							/>
						}
					</div>
				}
					<div className={!itm?.Opis ? `d-flex align-items-center h-100` : ""}>
						<b>{t('zam.frmItem.nazw')}:</b>&nbsp;
						{itm?.ID !== custID ?
							<>
								{pllng ? itm?.Nazw : itm?.Nazw2}
							</>
						:
							<>
								<Form.Control 
									as="textarea" 
									defaultValue={''}
									rows={1}
									placeholder={t('zam.frmItem.addtitle')}
									onChange={(e) => setNewValDscr(e.target.value)}
								/>
							</>
						}
						{!!itm?.Opis ?
						// <div style={{whiteSpace: 'pre-line'}}>
							<div className="text-wrap pre text-justify lh-sm">
							<hr className="m-1"/>
								<b>{t('zam.frmItem.dsc')}: </b>
								<br />
								<span className="text-muted">
									<small >
										{itm?.Opis.replace(/^\n+|\n+$/g, '').trim()}
									</small>
								</span>
							</div>
						:
							""
						}
					</div>
			</Col>
		</>
	)
}



/**
 * Modal for Image
 * @param {*} param0 
 * @returns 
 */
const ImgModal = ({img, mime, vis, setVis}) =>{
	
	const closeImgModal = async () => {
		setVis(false);
	};

	return (
		<>
			{/* Modal of NOT resized img */}
			<Modal 
				dialogClassName="full-screen-modal"
				show={vis} 
				onHide={closeImgModal}
				onClick={closeImgModal}
				centered
			>
				<Modal.Body className="m-0 p-0">
					{img && (
						<Image 
							className="full-screen-image rounded-2"
							src={`data:${mime};base64,${img}`} 
							style={{ width: '100%', height: 'auto' }}
						/>
					)}
				</Modal.Body>
			</Modal>
		</>
	)
}