import React from 'react'
import { FaTrash } from 'react-icons/fa';

import useCardContext from '../Context/CartContext';
import { Col, Row } from 'react-bootstrap';
import { ArrowBadge } from './ShopCart';
import { IsPLlng } from '../js/func';

export const CartSmallItem = ({item, config}) => {
	const pllng = IsPLlng();
	const { DeleteFromCart } = useCardContext();
	// Cechy of current object
	const cechyString = Object.entries(item.cechy)
		//.map(([key, value]) => `${key}: "${value?.sel[0]}"`)
		.map(([key, value]) => `${pllng ? key : value.name}: "${pllng ? Object.keys(value.sel)[0] : Object.values(value.sel)[0]}"`)
		.join(', ');

	return (
		<>
			<div className='item'>
				<Row className="align-items-center">
					<Col>
						<strong>
							{config?.Nazw3[9] === 'T' ? `${item.Kod || `[NOCODE]`} - ` : ""}
							{config?.Nazw3[10] === 'T' ? `(${item.kat || `[NOCAT]`}) - ` : ""}
							{pllng ? item.Nazw : item.Nazw2}
						</strong>
						<br />
						<small style={{ display: "inline-block", paddingLeft: "20px" }}>
							<i>
								{cechyString}
							</i>
						</small>
					</Col>
					<Col className='col-auto '>
						<ArrowBadge itm={item} />
					</Col>
					<Col className='col-auto'>
						<FaTrash 
							className='delete-item-icon' 
							onClick={() => DeleteFromCart(item)}
						/>
						<br />
					</Col>
				</Row>
			</div>
		</>
	)
}

