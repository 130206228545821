/* File for config of localization */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';


i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(LanguageDetector)
	.use(HttpApi)
	.init({
		// the translations
		// (tip move them in a JSON file and import them,
		// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

		//lng: document.querySelector('html').lang,
		//lng: "en", // if you're using a language detector, do not define the lng option
		supportedLngs: [
			'pl', 
			'en', 
			//'ru'
		],
		fallbackLng: "pl", // use pl if detected lng is not available
		detection: {
			// order and from where user language should be detected
			order: [
				'querystring', 
				'path', 
				'cookie', 
				'localStorage', 
				'sessionStorage', 
				'navigator', 
				'subdomain', 
				'htmlTag'
			],
			// cache user language on
			caches: [
				'localStorage', 
				'cookie'
			],
		},

		/*interpolation: {
			escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		}*/
		backend: {
			//loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
			//loadPath: '/assets/locales/{{lng}}/translation.json',
			loadPath: '/locale/{{lng}}.json',
		},
		//    react: {useSuspense: false}
	});




	export const langs = [
		{
			code: 'pl',
			name: 'Polska',
			country_code: 'pl',
			direction: 'ltr'
		},
		{
			code: 'en',
			name: 'English',
			country_code: 'us',
			direction: 'ltr'
		},
		/*{
			code: 'ru',
			name: 'Русский',
			country_code: 'ru',
			direction: 'ltr'
		},
		{
			code: 'ar',
			name: 'Arabic',
			country_code: 'ar',
			direction: 'rtl'
		}*/
	];
	

export default i18n;