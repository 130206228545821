import React from 'react';
import { Card, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BiAward } from "react-icons/bi";
/* for translate */
import { useTranslation } from 'react-i18next';
import { cntr_horiz } from '../App';

export const AboutShops = ({ get }) => {


	if (get)
		console.log("GET: ", get);


	const {t} = useTranslation();
	let placement = "bottom";
	return(
		<>
			<div>
				<Container fluid>
					<Card className='shadow'>
						<Card.Header className={`${cntr_horiz} card-new`}  style={{ display: 'flex', alignItems: 'center' }}>
							<OverlayTrigger
								placement={placement}
								overlay={
									<Tooltip id={`tooltip-${placement}`}>
										{t("about.hdr")}
									</Tooltip>
								}
							>
								<span style={{ display: 'flex', alignItems: 'center' }}>
									<BiAward color="#008f00" size={25} /> &nbsp;
									<strong>{t("about.hdr")}</strong>
								</span>
							</OverlayTrigger>
						</Card.Header>
						<Card.Body >
							<div>
								{/* Zgodnie z art. 173 ust. 1 ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz. U. z 2004 r. Nr 171, poz. 1800) informujemy, że podmiotem zamieszczającym na Państwa urządzeniu końcowym pliki cookies jest operator witryny Fundacja ESTONTA z siedzibą we Wrocławiu, znajdująca się przy ul. Jana Marii Hoene-Wrońskiego 24/25, jej adres korespondencyjny 90-515 Łódź, przy ul. Wólczańskiej 81
								<br /> <br /> */}
								<ol className='cook'>
									<li>
										Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.
									</li>
									<li>
										Pliki cookies wykorzystywane są w celu:
										<ul>
											<li>
												dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;
											</li>
											<li>
												tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;
											</li>
											<li>
												utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;
											</li>
											<li>
												dostosowania treści reklam i  umożliwiają dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.
											</li>
										</ul>
									</li>
									<li>
										W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.
									</li>
									<li>
										W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).
									</li>
									<li>
										Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.
									</li>
									<li>
										Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również przez współpracujących z operatorem Serwisu reklamodawców oraz partnerów.
									</li>
									<li>
										Więcej informacji na temat plików cookies dostępnych jest pod adresem <a href="https://wszystkoociasteczkach.pl" target='_blank' rel="noreferrer">wszystkoociasteczkach.pl</a> lub w sekcji „Pomoc” w menu przeglądarki internetowej.
									</li>
								</ol>
							</div>
						</Card.Body>
					</Card>
				</Container>
			</div>
		</>
	)
}