/* eslint-disable eqeqeq */
import { createContext, useContext, useEffect, useState } from "react";
import axios from "../api/axios";
import cookies from 'js-cookie';
import { ErrMsg } from "../Pages/Msgs/ErrorMsg";
import { renderToStaticMarkup } from 'react-dom/server'
import { SpinnerWait } from "../Pages/Layouts/SpinnerWait";

const AxiosContext = createContext({});

export const AxiosProvider = ({ children }) => {

	useEffect(()=>{
		axios.defaults.headers.common['User-Lng'] = (cookies.get('i18next') || 'pl');
		axios.defaults.headers.common['Accept-Language'] = `${localStorage.getItem("i18nextLng") || (cookies.get('i18next') || 'pl')},en`;
		//'Accept-Language': `${localStorage.getItem("i18nextLng") || 'pl'},en`,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem("i18nextLng"), cookies.get('i18next')]);

	// for spinner
	const[spinner, setSpinner] = useState(0);
	const showSpin = () => {
		setSpinner(1);
	}
	const hideSpin = () => {
		setSpinner(0);
	}

	const back_url = '/';

	const [req_dataerr, setReq_dataerr] = useState({err: false, dsc: ""});
	const [req_data, setReq_data] = useState([]);

	/**
	 * Set error message!!!
	 */
	/*const SetError = (response) =>{
		console.log("error ")
		console.log(response)

		let logout = false;
		if (response?.data?.error?.error == 'token_no_found')
			logout = true;

		let resp = (response?.data?.message ? 
				response?.data?.message 
			: 
				response?.data?.error?.message
		)
		setReq_dataerr({
			err: true, 
			dsc: ((resp =="" || resp == undefined) ? 'Nieznany błąd' : resp ), 
			url: (response.status == 404 ? '/' : null), 
			logout: (logout || false) 
		})
	}*/
	const SetError = (error) =>{
		console.log("error ")
		console.log(error?.message)

		let logout = false;
		if (error?.response?.data?.error?.error == 'token_no_found' || error?.response?.data?.error?.error == 'not_logged')
			logout = true;

		let resp = (error?.response?.data?.message ? 
				error?.response?.data?.message 
			: 
				error?.response?.data?.error?.message || error?.message
		)
		
		let url = (error?.response?.data?.url || error?.response?.data?.error?.url || error?.url);
		setReq_dataerr({
			err: true, 
			dsc: ((resp =="" || resp == undefined) ? 'Nieznany błąd' : resp ), 
			url: ((error?.response?.status == 404 || error?.response?.status == 405) ? '/' : (url || null)), 
			logout: (logout || false) 
		})
	}

	/**
	 * GET data by axios request (CREATE)
	 */
	const Get_data = async (url, parameters) => {
		//if last symbol is - /
		url = url.replace(/\/$/,"")
		//if (url[url.length - 1] == '/') url.slice(0, -1)
		setReq_data([]);
		setReq_dataerr({err: false})
		if (!url){
			setReq_dataerr({err: true, dsc: "No url!"})
			return 0;
		}
		showSpin();
		await axios
			.get(
				back_url + url,
				{
					params: {
						...parameters
					},
				}
			)
			.then(response => {
				if(response.status !== 200){
					throw new Error(response);
				}
				return response.data;
			})
			.then(data=> {
				setReq_data(data);
				return data;
			})
			.catch((error) => {
				SetError(error)
			})
			/*.catch(({response}) => {
				SetError(response)
			})*/
			.finally((response) => {
				hideSpin();
				return response;
			});
	};

	/**
	 * POST data by axios request
	 */
	const Post_data = async (url, {...data}) => {

		//if last symbol is - /
		url = url.replace(/\/$/,"")
		//if (url[url.length - 1] == '/') url.slice(0, -1)

		setReq_data([]);
		setReq_dataerr({err: false})
		if (!url){
			setReq_dataerr({err: true, dsc: "No url!"})
			return 0;
		}
		showSpin();
		await axios
			.post(
				back_url + url,
				JSON.stringify(data)
			)
			.then(response => {
				if(response.status !== 200){
					throw new Error(response);
				}
				return response.data;
			})
			.then (data => {
				try {
					//let qty = parseInt(data)
					setReq_data(data);
					/*if (qty == 1){
						setToast({show: true, msg:"Dane zostały pomyślnie zapisane"});
					}*/
					return(data);
				} 
				catch (error) {
					throw new Error(data);
				}
			})
			.catch((error) => {
				SetError(error)
			})
			.finally(response => {
				hideSpin();
				return response;
			});
		return 0;
	};

	/**
	* PUT data by axios request (UPDATE)
	*/
	const Put_data = async (url, {...data}) => {

		//if last symbol is - /
		url = url.replace(/\/$/,"")
		//if (url[url.length - 1] == '/') url.slice(0, -1)

		setReq_data([]);
		setReq_dataerr({err: false})
		if (!url){
			setReq_dataerr({err: true, dsc: "No url!"})
			return 0;
		}
		showSpin();
		await axios
			.put(
				back_url + url,
				JSON.stringify(data)
			)
			.then(response => {
				if(response.status !== 200){
					throw new Error(response);
				}
				return response.data;
			})
			.then (data => {
				try {
					setReq_data(data);
					return(data);
				} 
				catch (error) {
					throw new Error(data);
				}
			})
			.catch((error) => {
				SetError(error)
			})
			.finally(response => {
				hideSpin();
				return response;
			});
		return 0;
	};
	
	

	return (
		<AxiosContext.Provider value={{ req_dataerr, setReq_dataerr, req_data, setReq_data, Get_data, Post_data, Put_data, showSpin, hideSpin, spinner }}>
			{/* Spinner for Axios Requests */}
			<SpinnerWait show={spinner}>
				{req_dataerr.err ? 
					<ErrMsg data={renderToStaticMarkup(req_dataerr.dsc)} show={req_dataerr.err} modal={req_dataerr?.url ? false : true} url={req_dataerr?.url || null} logout={req_dataerr?.logout || false} />
				:
					""
				}

				{children}
			</SpinnerWait>
		</AxiosContext.Provider>);
}



export default function useAxiosContext () {
	return useContext(AxiosContext);
}