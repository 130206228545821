
import { parse_to_JS } from "./func";
import { FirmCONFIG_Local_Storage, FirmINFO_Local_Storage, USERCONFIG_Local_Storage } from "./global";

/* Get main configs of firm from local or session storage */
const GetFirmConfig = () => {
	let cfg = parse_to_JS(sessionStorage.getItem(FirmCONFIG_Local_Storage));
	if (!cfg)
		cfg = parse_to_JS(localStorage.getItem(USERCONFIG_Local_Storage));
	//console.log('CFG', cfg);
	return (cfg || []);
}

/* Get info about firm from local or session storage */
const GetFirmInfo = () => {
	let info = parse_to_JS(sessionStorage.getItem(FirmINFO_Local_Storage));
	if (!info)
		info = parse_to_JS(localStorage.getItem(FirmINFO_Local_Storage));
	return (info || []);
}

/* Praca w trybie Gosc */
export const IsGuestAllow = () => {
	let cfg = GetFirmConfig();
	if (cfg && Array.isArray(cfg['Nazw3']) && cfg['Nazw3'].length > 3) {
		const guest = cfg['Nazw3'][3];
		return guest === 'T';
	}
	
	return false;
}


/* Get firm ID - Alias*/
export const FirmName = () => {
	return (GetFirmInfo()['IDFirmy'] || false);
}