import React from "react";
import { ErrMsg } from "./Msgs/ErrorMsg";
import { renderToStaticMarkup } from 'react-dom/server'


/* Message of Error 404 */
const errmsg404 = (
	<>
		<h2><center>Błąd 404</center></h2>
			<div className="mb-3">
				<strong><b>Nie znaleziono strony.</b></strong>
			</div>
				Strona, której szukasz nie istnieje. <br />Użyj przycisku wstecz lub przejdź do głównej strony aplikacji.
	</>
);


const R404 = () => {
	return (
		<ErrMsg data={renderToStaticMarkup(errmsg404)} show={true} />
	);
};

export default R404;